import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { IWrBackupItemResponse } from '@/api/types'
import type {
  BackupState,
  BackupLocalMeta,
  IDirectoryBackupMeta,
  IDirectoryBackupOperation,
} from '@/store/modules/client/backup/types'

type BackupTree = GetterTree<BackupState, RootState>

import { compareAsc } from 'date-fns'
import cloneDeep from 'lodash/cloneDeep'
import { getWrIcons } from '@/helpers/wr-icons'
import {
  getFormattedDate,
  getFormattedSize,
} from '@/helpers/formatted-functions'

export const getters: BackupTree = {
  /**
   * backupTree
   * ? Дерево директорий резервной копии
   *
   * @param {BackupState} state data
   * @returns {Partial<IDirectoryBackupOperation>} дерево директорий резервной копии
   */
  backupTree: (state: BackupState): Partial<IDirectoryBackupOperation> => {
    return state.backupTree
  },

  /**
   * backupTreeMeta
   * ? Дерево мета данных директорий резервной копии
   *
   * @param {BackupState} state data
   * @returns {Partial<IDirectoryBackupMeta>} дерево мета данных директорий резервной копии
   */
  backupTreeMeta: (state: BackupState): Partial<IDirectoryBackupMeta> => {
    return state.backupTreeMeta
  },

  /**
   * currentView
   * ? Текущий вид резервных копий
   *
   * @param {BackupState} state data
   * @returns {string} текущий вид резервных копий
   */
  currentView: (state: BackupState): string => {
    return state.currentView
  },

  /**
   * currentBackup
   * ? Текущая резервная копия
   *
   * @param {BackupState} state data
   * @returns {Partial<IWrBackupItemResponse>} дерево мета данных директорий резервной копии
   */
  currentBackup: (state: BackupState): Partial<IWrBackupItemResponse> => {
    return state.currentBackup
  },

  /**
   * currentBackupDirectory
   * ? Текущая (выбранная) директория резервной копии
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {IWrBackupItemResponse[]} текущая (выбранная) директория резервной копии
   */
  currentBackupDirectory: (
    state: BackupState,
    getters
  ): IWrBackupItemResponse[] => {
    const { currentBackupQueryPath } = getters

    if (!(currentBackupQueryPath in state.backupTree)) {
      return []
    }

    const backupItems = state.backupTree[currentBackupQueryPath]?.items ?? []

    return backupItems.map((backup) => {
      const { size, type, subtype, updated_at } = backup

      return {
        ...backup,
        icon: getWrIcons(subtype ?? type),
        formatSize: getFormattedSize(size),
        formatUpdatedDateTime: getFormattedDate(updated_at, true),
      }
    })
  },

  /**
   * treeDirectoryPath
   * ? Список сформированных путей директорий дерева
   *
   * @param {BackupState} state data
   * @returns {Partial<string[]>} список сформированных путей директорий дерева
   */
  treeDirectoryPath: (state: BackupState): Partial<string[]> => {
    return state.treeDirectoryPath
  },

  /**
   * currentBackupName
   * ? Имя текущей резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} имя текущей резервной копии
   */
  currentBackupName: (state: BackupState): string => {
    return state.currentBackupName
  },

  /**
   * currentBackupSubtype
   * ? Тип текущей резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} тип текущей резервной копии
   */
  currentBackupSubtype: (state: BackupState): string => {
    return state.currentBackupSubtype
  },

  /**
   * currentBackupVersion
   * ? Текущая версия резервной копии
   *
   * @param {BackupState} state data
   * @returns {string} текущая версия резервной копии
   */
  currentBackupVersion: (state: BackupState): string => {
    return state.currentBackupVersion
  },

  /**
   * currentBackupQueryPath
   * ? Текущий выбранный путь запроса получения нужной директории
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @returns {string} текущий выбранный путь запроса получения нужной директории
   */
  currentBackupQueryPath: (state: BackupState, getters): string => {
    const { currentBackupName, currentBackupVersion, treeDirectoryPath } =
      getters

    return [currentBackupName, currentBackupVersion, ...treeDirectoryPath].join(
      '/'
    )
  },

  /**
   * currentBackupVersions
   * ? Список версий текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {IWrBackupItemResponse[]} список версий текущей резервной копий
   */
  currentBackupVersions: (state: BackupState): IWrBackupItemResponse[] => {
    return cloneDeep(state.currentBackupVersions).sort((a, b) => {
      return compareAsc(new Date(b.updated_at), new Date(a.updated_at))
    })
  },

  /**
   * defaultBackupMeta
   * ? Дефолтные мета данные содержимого бэкапа
   *
   * @param {BackupState} state data
   * @returns {BackupLocalMeta} дефолтные мета данные содержимого бэкапа
   */
  defaultBackupMeta: (state: BackupState): BackupLocalMeta => {
    return state.defaultBackupMeta
  },

  /**
   * hasCurrentBackupVersion
   * ? Признак существования списка текущей резервной копии
   *
   * @param {BackupState} state data
   * @param {object} getters computed
   * @param {RootState} rootState root data
   * @returns {boolean} признак существования списка текущей резервной копии
   */
  hasCurrentBackupVersion: (
    state: BackupState,
    getters,
    rootState: RootState
  ): ((name: string) => boolean) => {
    return (name): boolean => {
      const { backups } = rootState

      if (!backups || Object.keys(backups.versions).length === 0) {
        return false
      }

      return name in backups.versions
    }
  },

  /**
   * isLoadingCurrentBackup
   * ? Процесс загрузки текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки текущей резервной копий
   */
  isLoadingCurrentBackup: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackup
  },

  /**
   * isLoadingUrlBackupImage
   * ? Процесс загрузки текущего пути изображения
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки текущего пути изображения
   */
  isLoadingUrlBackupImage: (state: BackupState): boolean => {
    return state.isLoadingUrlBackupImage
  },

  /**
   * isLoadingCurrentBackupVersions
   * ? Процесс загрузки списка версий текущей резервной копий
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки списка версий текущей резервной копий
   */
  isLoadingCurrentBackupVersions: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackupVersions
  },

  /**
   * isLoadingFirstDirectoryBackupItems
   * ? Процесс загрузки первых директорий резервной копии
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки первых директорий резервной копии
   */
  isLoadingFirstDirectoryBackupItems: (state: BackupState): boolean => {
    return state.isLoadingFirstDirectoryBackupItems
  },

  /**
   * isLoadingNextDirectoryBackupItems
   * ? Процесс загрузки следующих директорий архива
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс загрузки следующих директорий архива
   */
  isLoadingNextDirectoryBackupItems: (state: BackupState): boolean => {
    return state.isLoadingNextDirectoryBackupItems
  },

  /**
   * isGroupLoadingCurrentBackupFolderDownload
   * ? Процесс множественного скачивания папок резервной копии
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс множественного скачивания папок резервной копи
   */
  isGroupLoadingCurrentBackupFolderDownload: (
    state: BackupState
  ): ((name: string) => boolean) => {
    return (name) => {
      return state.groupLoadingCurrentBackupFolderDownload[name]
    }
  },

  /**
   * isLoadingCurrentBackupFileDownload
   * ? Процесс скачивания выбранного файла резервной копий
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс скачивания выбранного файла резервной копий
   */
  isLoadingCurrentBackupFileDownload: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackupFileDownload
  },

  /**
   * isLoadingCurrentBackupFolderDownload
   * ? Процесс скачивания выбранной папки резервной копий
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс скачивания выбранной папки резервной копий
   */
  isLoadingCurrentBackupFolderDownload: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackupFolderDownload
  },

  /**
   * isLoadingCurrentBackupMultipleItems
   * ? Процесс скачивания файлов и папок
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс скачивания файлов и папок
   */
  isLoadingCurrentBackupMultipleItems: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackupMultipleItems
  },

  /**
   * isLoadingCurrentBackupVersionDownload
   * ? Процесс скачивания текущей версии резервной копий
   *
   * @param {BackupState} state data
   * @returns {boolean} процесс скачивания текущей версии резервной копий
   */
  isLoadingCurrentBackupVersionDownload: (state: BackupState): boolean => {
    return state.isLoadingCurrentBackupVersionDownload
  },
}
