export const en = {
  /**
   * ? Product name
   */
  'product.name.cyber-backup': 'Cyber Backup',
  'product.edition.advanced': 'Advanced edition',
  'product.edition.standard': 'Standard edition',

  /**
   * ? Edition staff
   */
  'edition.staff.name.mailbox': 'Mailbox',
  'edition.staff.name.mailbox-5': 'Mailbox • 5 mailboxes',
  'edition.staff.name.mailbox-25': 'Mailbox • 25 mailboxes',
  'edition.staff.name.mailbox-50': 'Mailbox • 50 mailboxes',
  'edition.staff.name.mailbox-100': 'Mailbox • 100 mailboxes',
  'edition.staff.name.mailbox-500': 'Mailbox • 500 mailboxes',
  'edition.staff.name.mailbox-1000': 'Mailbox • 1000 mailboxes',
  'edition.staff.name.workstation': 'Workstation',
  'edition.staff.name.virtual-host': 'Virtual Host',
  'edition.staff.name.server': 'Server',
  'edition.staff.name.universal-license': 'Universal',
  'edition.staff.name.postgresql': 'PostgreSQL',

  /**
   * ? FIELDS: label
   */
  'form.field.label.inn': 'INN',
  'form.field.label.company': 'Company',

  /**
   * ? FIELDS: validate
   */
  'form.field.validate.company-required': 'Choose a company.',

  /**
   * ? FIELDS placeholder
   */
  'field.placeholder.select.search-company': 'Search by name, TIN and address',

  /**
   * ? COMPONENT: Tag
   */
  'component.tag.name.trial-license': 'Trial',
  'component.tag.name.perpetual-license': 'Perpetual',

  /**
   * ? COMPONENT: Buttons
   */
  'component.button.name.documentation': 'Documentation',
  'component.button.name.buy-full-version': 'Buy full version',
  'component.button.name.buy': 'Buy',
  'component.button.name.download-distribution': 'Download distribution',

  /**
   * ? COMPONENT: Select
   */
  'component.select.search.title.company-example':
    'Example search (up to 20 results are displayed):',
  'component.select.search.text.company-name':
    'Romashka LLC, 1234567890, St. Petersburg',

  /**
   * ? COMPONENT: Table
   */
  'component.table.cell.unlimited': 'Unlimited',

  /**
   * ? COMPONENT: Buttons
   */
  'component.button.name.try': 'Try',
  'component.button.name.re-enter': 'Re-enter',
  'component.button.name.buy-key': 'Buy a key',

  /**
   * ? COMPONENT: Popover
   */
  'component.popover.distribution.title': 'To use the trial version',
  'component.popover.distribution.download': '1. Download the distribution kit',
  'component.popover.distribution.install':
    '2. Install the product and activate the trial version in the product',

  /**
   * ? COMPONENT: Header
   */
  'component.header.title.products': 'Products',
  'component.header.title.undefined': 'Section not found',

  /**
   * ? PAGE: Error
   */
  'page.errors.title.account-type-not-found': 'Account type not defined',

  /**
   * ? PAGE: Products
   */
  'page.products.card.title.no-key': "You don't have a key yet",
  'page.products.card.title.try-product': 'You want to try the product',
  'page.products.card.text.perpetual-license':
    'Purchase a perpetual or subscription-based license',
  'page.products.card.text.trial-version':
    'Get a 30-day full-featured trial version',
}
