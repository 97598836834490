import type { MutationTree } from 'vuex'
import type { SkusState } from '@/store/modules/admin/skus/types'
import type { SkuResponse } from '@/api/types'

type SkusTree = MutationTree<SkusState>

import { setLoading } from '@/helpers/mutation-functions'

export const mutations: SkusTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_SKUS
   * ? Установить список всех sku
   *
   * @param {SkusState} state data
   * @param {SkuResponse[]} skus список всех sku
   * @returns {void}
   */
  SET_SKUS: (state: SkusState, skus: SkuResponse[]): void => {
    state.skus = skus
  },
}
