var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({ref:"ui-scrollbar",staticClass:"ui-scrollbar",class:{ 'ui-scrollbar--textarea': _vm.hasTextareaContent },style:(_vm.styles)},_vm.$listeners),[(_vm.disabled)?[_vm._t("default")]:[_c('main',{staticClass:"scrollbar-main"},[_c('div',{ref:"scrollbar-content",staticClass:"scrollbar-content",on:{"scroll":function($event){return _vm.scroll($event)}}},[_vm._t("default")],2),(_vm.visibleScrollBarY)?_c('aside',{staticClass:"scrollbar-aside-y",class:{
          'scrollbar-aside-y__internal-mode': _vm.enableInternalMode,
          'scrollbar-aside-y__external-mode': !_vm.enableInternalMode,
          'scrollbar-aside-y__interval-captured':
            _vm.enableInternalMode && _vm.isCapturedSlider && _vm.currentSide === 'Y',
        }},[_c('div',{staticClass:"scrollbar-container-y"},[_c('div',{ref:"scrollbar-content-y",staticClass:"scrollbar-content-y"},[_c('div',{staticClass:"scrollbar-slider-y",on:{"mousedown":function($event){return _vm.dragScrollStart($event, 'Y')}}})])]),(_vm.hasTextareaContent && _vm.areaDragRule !== 'none')?_c('div',{staticClass:"scrollbar-resize-container"},[_c('div',{staticClass:"scrollbar-resize-item",on:{"mousedown":_vm.dragTextareaResizeStart}},[_c('vpc-icon',{attrs:{"name":"area-drag","color":_vm.sliderColor,"width":_vm.overallSliderSize,"height":_vm.overallSliderSize}})],1)]):_vm._e()]):_vm._e()]),(_vm.visibleScrollBarX)?_c('aside',{staticClass:"scrollbar-aside-x",class:{
        'scrollbar-aside-x__internal-mode': _vm.enableInternalMode,
        'scrollbar-aside-x__external-mode': !_vm.enableInternalMode,
        'scrollbar-aside-x__interval-captured':
          _vm.enableInternalMode && _vm.isCapturedSlider && _vm.currentSide === 'X',
      }},[_c('div',{staticClass:"scrollbar-container-x"},[_c('div',{ref:"scrollbar-content-x",staticClass:"scrollbar-content-x"},[_c('div',{staticClass:"scrollbar-slider-x",on:{"mousedown":function($event){return _vm.dragScrollStart($event, 'X')}}})])]),(_vm.visibleScrollBarY)?_c('div',{staticClass:"scrollbar-cross-container"},[_c('div',{staticClass:"scrollbar-cross-item"})]):_vm._e()]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }