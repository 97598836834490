// Общее состояние
import { nav } from '@/store/modules/nav'
import { account } from '@/store/modules/account'

// Личный кабинет клиента
import { backup } from '@/store/modules/client/backup'
import { support } from '@/store/modules/client/support'
import { product } from '@/store/modules/client/product'
import { archive } from '@/store/modules/client/archive'
import { backups } from '@/store/modules/client/backups'
import { archives } from '@/store/modules/client/archives'
import { products } from '@/store/modules/client/products'
import { operation } from '@/store/modules/client/operation'

// Админ панель
import { skus } from '@/store/modules/admin/skus'
import { sku } from '@/store/modules/admin/sku'
import { user } from '@/store/modules/admin/user'
import { users } from '@/store/modules/admin/users'
import { order } from '@/store/modules/admin/order'
import { orders } from '@/store/modules/admin/orders'
import { license } from '@/store/modules/admin/license'
import { licenses } from '@/store/modules/admin/licenses'
import { campaign } from '@/store/modules/admin/campaign'
import { campaigns } from '@/store/modules/admin/campaigns'
import { reportSession } from '@/store/modules/admin/report-session'

// Бизнес кабинет
import { externalProducts } from '@/store/modules/business/external-products'

export const modules = {
  // Общее состояние
  nav,
  account,

  // Личный кабинет клиента
  backup,
  support,
  product,
  archive,
  backups,
  archives,
  products,
  operation,

  // Админ панель
  skus,
  sku,
  user,
  users,
  order,
  orders,
  license,
  licenses,
  campaign,
  campaigns,
  reportSession,

  // Бизнес кабинет
  externalProducts,
}
