import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { LocaleMessage } from 'vue-i18n'
import type { NavState, HomePage } from '@/store/modules/nav/types'

type NavTree = GetterTree<NavState, RootState>

export const getters: NavTree = {
  /**
   * homePage
   * ? Домашняя страница с учетом ролей
   *
   * @param {NavState} state data
   * @param {any} g getters
   * @param {any} rs root state
   * @param {any} rootGetters root getters
   * @returns {HomePage} домашняя страница с учетом ролей
   */
  homePage: (state: NavState, g, rs, rootGetters): HomePage => {
    return state.homePages[rootGetters['account/userRole']]
  },

  /**
   * currentQuery
   * ? Текущие параметры маршрутов
   *
   * @param {NavState} state data
   * @returns {string} текущие параметры маршрутов
   */
  currentQuery: (state: NavState): string => {
    return state.route?.query
  },

  /**
   * currentRouteName
   * ? Имя текущий страницы
   *
   * @param {NavState} state data
   * @returns {string} текущий маршрут
   */
  currentRouteName: (state: NavState): string => {
    return state.route?.name ?? ''
  },

  /**
   * currentPage
   * ? Текущая страница
   *
   * @param {NavState} state data
   * @param {object} getters computed
   * @returns {LocaleMessage} текущая страница
   */
  currentPage: (state: NavState, getters): LocaleMessage => {
    return (
      state.pageTitles[getters.currentRouteName] ?? state.pageTitles.default
    )
  },

  /**
   * isNavVisible
   * ? Признак видимости меню навигации
   *
   * @param {NavState} state data
   * @returns {boolean} признак раскрытого меню навигации
   */
  isNavVisible: (state: NavState): boolean => {
    return state.isNavVisible
  },

  /**
   * isNavFullyOpen
   * ? Признак раскрытого меню навигации
   *
   * @param {NavState} state data
   * @param {Array} rest getters/rootState/rootGetters
   * @returns {boolean} признак раскрытого меню навигации
   */
  isNavFullyOpen: (state: NavState, ...rest): boolean => {
    const [, , rootGetters] = rest

    return rootGetters.isMobile ? true : state.isNavFullyOpen
  },

  /**
   * urlWebRestore
   * ? Ссылка на внешний хост Web Restore
   *
   * @param {NavState} state data
   * @returns {string} ссылка на внешний хост Web Restore
   */
  urlWebRestore: (state: NavState): string => {
    return state.urlWebRestore
  },

  /**
   * isNewWebrestore
   * ? Признак отображения нового WR
   *
   * @param {NavState} state data
   * @returns {string} признак отображения нового WR
   */
  isNewWebrestore: (state: NavState): boolean => {
    return state.isNewWebrestore
  },
}
