import type { RootState } from '@/store/types'

import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { modules } from '@/store/modules'
import { actions } from '@/store/actions'
import { getters } from '@/store/getters'
import { mutations } from '@/store/mutations'
import { saveStates } from './manager'

Vue.use(Vuex)

type Store = StoreOptions<RootState>

const state: RootState = {
  isLoading: false,
  serverError: null,
  displayWidth: 0,
  displayHeight: 0,
  hasUserCommonError: false,
  hasAdminCommonError: false,
  userCommonErrorMessage: null,
}
const store: Store = {
  state,
  modules,
  actions,
  getters,
  mutations,
}

saveStates(modules)

export default new Vuex.Store(store)
