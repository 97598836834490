import type {
  IArchivesState,
  TArchivesModule,
} from '@/store/modules/client/archives/types'

import { getters } from '@/store/modules/client/archives/getters'
import { actions } from '@/store/modules/client/archives/actions'
import { mutations } from '@/store/modules/client/archives/mutations'

const getArchivesMeta = () => {
  return {
    page: 1,
    display_name: '',
    direction: 'desc',
    order_by: 'created_at',
  }
}

const state: IArchivesState = {
  archives: [],
  archiveVersionMap: {},
  isLoadingArchives: false,
  isLoadingArchiveDelete: false,
  isLoadingArchiveVersions: false,
  currentArchivesMeta: getArchivesMeta(),
  defaultArchivesMeta: getArchivesMeta(),
}

export const archives: TArchivesModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
