import store from '@/store'
import axios from 'axios'
import { reloadPage } from '@/helpers'
import { ROLES } from '@/config/constants'
import { API_ERROR } from '@/config/constants'

const axiosApi = axios.create()

// Response interceptor
axiosApi.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const { response } = error
    const status = response.status
    const statusListVisible = [4, 5]
    const shortStatus = Number(String(status)[0])
    const isUserLoggedIn = store.getters['account/isUserLoggedIn']

    let data = response.data

    /**
     * Обрабатываем ошибки для responseType: blob
     */
    if (data instanceof Blob) {
      data = JSON.parse(await data.text())
    }

    const isHandledError = data.code in API_ERROR
    const isUnAuthorized = data.code === API_ERROR.UNAUTHORIZED

    if (isUserLoggedIn && isUnAuthorized) {
      reloadPage()

      return null
    }

    if (!isHandledError && statusListVisible.includes(shortStatus)) {
      const userRole = store.getters['account/userRole']

      ROLES.USER === userRole
        ? store.commit('OPEN_USER_COMMON_ERROR')
        : store.commit('OPEN_ADMIN_COMMON_ERROR')
    }

    store.commit('SET_SERVER_ERROR', data)
    return Promise.reject(data)
  }
)

export default axiosApi
