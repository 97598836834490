import type { MutationTree } from 'vuex'
import type { OrderState } from '@/store/modules/admin/order/types'
import type {
  SkuResponse,
  OrderResponse,
  ProductResponse,
  AccountResponse,
} from '@/api/types'

type OrderTree = MutationTree<OrderState>

import { setLoading } from '@/helpers/mutation-functions'

export const mutations: OrderTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_CURRENT_ORDER
   * ? Установить текущий ордер
   *
   * @param {OrderState} state data
   * @param {OrderResponse} order текущий ордер
   * @returns {void}
   */
  SET_CURRENT_ORDER: (state: OrderState, order: OrderResponse): void => {
    state.order = order
  },

  /**
   * SET_CURRENT_LICENSE
   * ? Установить текущую лицензию
   *
   * @param {OrderState} state data
   * @param {ProductResponse} license текущая лицензия
   * @returns {void}
   */
  SET_CURRENT_LICENSE: (state: OrderState, license: ProductResponse): void => {
    state.license = license
  },

  /**
   * SET_CURRENT_USER
   * ? Установить текущего пользователя
   *
   * @param {OrderState} state data
   * @param {AccountResponse} user текущий пользователь
   * @returns {void}
   */
  SET_CURRENT_USER: (state: OrderState, user: AccountResponse): void => {
    state.user = user
  },

  /**
   * SET_CURRENT_SKU
   * ? Установить текущий sku
   *
   * @param {OrderState} state data
   * @param {SkuResponse} sku текущий sku
   * @returns {void}
   */
  SET_CURRENT_SKU: (state: OrderState, sku: SkuResponse): void => {
    state.sku = sku
  },

  /**
   * CLEAR_ORDER_STATES
   * ? Очистить все состояния текущего ордера
   *
   * @param {OrderState} state data
   * @returns {void}
   */
  CLEAR_ORDER_STATES: (state: OrderState): void => {
    state.sku = null
    state.user = null
    state.order = null
    state.license = null
  },
}
