import type {
  MetaPayload,
  IExportPayload,
  IReportUserAccountsPayload,
  IReportUsersAccountsResponse,
  ReportOrdersDataResponse,
  ICreateReportSessionPayload,
  ICreateReportSessionResponse,
  AssignedLicensesDataResponse,
  IReportCampaignsDataResponse,
  AvailableLicensesDataResponse,
} from '@/api/services/report/types'

import axios from '@/api/axios'
import { ICampaignsPayload } from '@/api/types'

export default {
  /** ==== Report ==== */

  /**
   * createReportSession
   * ? Создать сессию для репортов
   *
   * @param {ICreateReportSessionPayload} data тело запроса (тип репорта сессии)
   * @returns {Promise<ICreateReportSessionResponse>} идентификатор сессии
   */
  createReportSession(
    data: ICreateReportSessionPayload
  ): Promise<ICreateReportSessionResponse> {
    return axios.post('/api/report/v1/sessions', data)
  },

  /**
   * getReportSession
   * ? Получить сессию репорта
   *
   * @param {string} id идентификатор сессии
   * @returns {Promise<ICreateReportSessionPayload>} информация по сессии
   */
  getReportSession(id: string): Promise<ICreateReportSessionPayload> {
    return axios.get(`/api/report/v1/sessions/${id}`)
  },

  /**
   * getReportUserAccounts
   * ? Получить отчет по аккаунтам пользователей
   *
   * @param {IReportUserAccountsPayload} params параметры конфигурирования списка пользователей
   * @returns {Promise<IReportUsersAccountsResponse>} отчет по аккаунтам пользователей
   */
  getReportUserAccounts(
    params: IReportUserAccountsPayload
  ): Promise<IReportUsersAccountsResponse> {
    return axios.get('/api/report/v1/accounts', { params })
  },

  /**
   * getAssignedLicenses
   * ? Получить список назначенных лицензий
   *
   * @param {MetaPayload} params параметры конфигурирования списка лицензий
   * @returns {Promise<AssignedLicensesDataResponse>} список назначенных лицензий
   */
  getAssignedLicenses(
    params: MetaPayload
  ): Promise<AssignedLicensesDataResponse> {
    return axios.get('/api/report/v1/assigned_licenses', { params })
  },

  /**
   * getAvailableLicenses
   * ? Получить список свободных лицензий
   *
   * @param {MetaPayload} params параметры конфигурирования списка лицензий
   * @returns {Promise<AvailableLicensesDataResponse>} список свободных лицензий
   */
  getAvailableLicenses(
    params: MetaPayload
  ): Promise<AvailableLicensesDataResponse> {
    return axios.get('/api/report/v1/available_licenses', { params })
  },

  /**
   * getReportCampaigns
   * ? Получить список кампаний
   *
   * @param {ICampaignsPayload} params параметры конфигурации запроса кампаний
   * @returns {Promise<IReportCampaignsDataResponse>} список кампаний
   */
  getReportCampaigns(
    params: ICampaignsPayload
  ): Promise<IReportCampaignsDataResponse> {
    return axios.get('/api/report/v1/campaigns', { params })
  },

  /**
   * getReportOrders
   * ? Получить список ордеров
   *
   * @param {MetaPayload} params параметры конфигурации запроса ордеров
   * @returns {Promise<ReportOrdersDataResponse>} список ордеров
   */
  getReportOrders(params: MetaPayload): Promise<ReportOrdersDataResponse> {
    return axios.get('/api/report/v1/orders', { params })
  },

  /**
   * exportAccounts
   * ? Экспорт всех аккаунтов
   *
   * @param {IExportPayload} params параметры конфигурации запроса
   * @returns {Promise<string>} список аккаунтов в csv формате
   */
  exportAccounts(params: IExportPayload): Promise<string> {
    return axios.get('/api/report/v1/accounts/file', { params })
  },

  /**
   * exportAssignedLicenses
   * ? Экспорт всех назначенных лицензий
   *
   * @param {IExportPayload} params параметры конфигурации запроса
   * @returns {Promise<string>} список назначенных лицензий в csv формате
   */
  exportAssignedLicenses(params: IExportPayload): Promise<string> {
    return axios.get('/api/report/v1/assigned_licenses/file', { params })
  },

  /**
   * exportAvailableLicenses
   * ? Экспорт всех свободных лицензий
   *
   * @param {IExportPayload} params параметры конфигурации запроса
   * @returns {Promise<string>} список свободных лицензий в csv формате
   */
  exportAvailableLicenses(params: IExportPayload): Promise<string> {
    return axios.get('/api/report/v1/available_licenses/file', { params })
  },

  /**
   * exportCampaigns
   * ? Экспорт всех кампаний
   *
   * @param {IExportPayload} params параметры конфигурации запроса
   * @returns {Promise<string>} список кампаний в csv формате
   */
  exportCampaigns(params: IExportPayload): Promise<string> {
    return axios.get('/api/report/v1/campaigns/file', { params })
  },

  /**
   * exportOrders
   * ? Экспорт всех ордеров
   *
   * @param {IExportPayload} params параметры конфигурации запроса
   * @returns {Promise<string>} список ордеров в csv формате
   */
  exportOrders(params: IExportPayload): Promise<string> {
    return axios.get('/api/report/v1/orders/file', { params })
  },
}
