import type { MutationTree } from 'vuex'
import type { IWrBackupItemResponse } from '@/api/types'
import type {
  BackupState,
  IDirectoryBackupMeta,
  IDirectoryBackupOperation,
  IDirectoryTargetBackupMeta,
  IDirectoryTargetBackupOperation,
} from '@/store/modules/client/backup/types'

type BackupTree = MutationTree<BackupState>

import Vue from 'vue'
import { setLoading } from '@/helpers/mutation-functions'

export const mutations: BackupTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_CURRENT_VIEW
   * ? Установить текущие вид резервных копий
   *
   * @param {BackupState} state data
   * @param {string} view имя выбранной резервных копий
   * @returns {void}
   */
  SET_CURRENT_VIEW: (state: BackupState, view: string): void => {
    state.currentView = view
  },

  /**
   * SET_CURRENT_BACKUP_NAME
   * ? Установить текущее имя резервной копии
   *
   * @param {BackupState} state data
   * @param {string} name имя выбранной резервной копии
   * @returns {void}
   */
  SET_CURRENT_BACKUP_NAME: (state: BackupState, name: string): void => {
    state.currentBackupName = name
  },

  /**
   * SET_CURRENT_BACKUP_SUBTYPE
   * ? Установить текущий тип резервной копии
   *
   * @param {BackupState} state data
   * @param {string} type тип выбранной резервной копии
   * @returns {void}
   */
  SET_CURRENT_BACKUP_SUBTYPE: (state: BackupState, type: string): void => {
    state.currentBackupSubtype = type
  },

  /**
   * SET_CURRENT_BACKUP
   * ? Установить текущую резервную копию
   *
   * @param {BackupState} state data
   * @param {IWrBackupItemResponse} backup текущая резервная копия
   * @returns {void}
   */
  SET_CURRENT_BACKUP: (
    state: BackupState,
    backup: IWrBackupItemResponse
  ): void => {
    state.currentBackup = backup
  },

  /**
   * SET_CURRENT_BACKUP_VERSION
   * ? Установить текущую версию резервной копии
   *
   * @param {BackupState} state data
   * @param {string} version текущая версия резервной копии
   * @returns {void}
   */
  SET_CURRENT_BACKUP_VERSION: (state: BackupState, version: string): void => {
    state.currentBackupVersion = version
  },

  /**
   * SET_CURRENT_BACKUP_VERSIONS
   * ? Установить список версий резервных копий
   *
   * @param {BackupState} state data
   * @param {IWrBackupItemResponse[]} versions список версий текущий резервной копии
   * @returns {void}
   */
  SET_CURRENT_BACKUP_VERSIONS: (
    state: BackupState,
    versions: IWrBackupItemResponse[]
  ): void => {
    state.currentBackupVersions = versions
  },

  /**
   * SET_BACKUP_DIRECTORY_TREE
   * ? Установить директорию бэкапа
   *
   * @param {BackupState} state data
   * @param {IDirectoryBackupOperation} data данные операции бэкапа
   * @returns {void}
   */
  SET_BACKUP_DIRECTORY_TREE: (
    state: BackupState,
    data: IDirectoryBackupOperation
  ): void => {
    state.backupTree = { ...state.backupTree, ...data }
  },

  /**
   * UPDATE_BACKUP_DIRECTORY_TREE
   * ? Обновить выбранную директорию бэкапа
   *
   * @param {BackupState} state data
   * @param {IDirectoryTargetBackupOperation} data данные по содержимому бэкапов
   * @param {string} data.path путь запроса
   * @param {IWrBackupItemResponse[]} data.backupOperation элементы бэкапа
   * @returns {void}
   */
  UPDATE_BACKUP_DIRECTORY_TREE: (
    state: BackupState,
    { path, backupOperation }: IDirectoryTargetBackupOperation
  ): void => {
    Vue.set(state.backupTree, path, backupOperation)
  },

  /**
   * SET_BACKUP_DIRECTORY_TREE_META
   * ? Установить дерево мета данных резервной копий
   *
   * @param {BackupState} state data
   * @param {IDirectoryBackupMeta} meta мета данные резервной копий
   * @returns {void}
   */
  SET_BACKUP_DIRECTORY_TREE_META: (
    state: BackupState,
    meta: IDirectoryBackupMeta
  ): void => {
    state.backupTreeMeta = { ...state.backupTreeMeta, ...meta }
  },

  /**
   * UPDATE_BACKUP_DIRECTORY_TREE_META
   * ? Обновить мета данные выбранной резервной копии
   *
   * @param {BackupState} state data
   * @param {IDirectoryTargetBackupOperation} options мета данные по содержимому бэкапов
   * @returns {void}
   */
  UPDATE_BACKUP_DIRECTORY_TREE_META: (
    state: BackupState,
    { path, meta }: IDirectoryTargetBackupMeta
  ): void => {
    Vue.set(state.backupTreeMeta, path, meta)
  },

  /**
   * SET_TREE_DIRECTORY_PATH
   * ? Установить список директорий резервной копий
   *
   * @param {BackupState} state data
   * @param {Partial<string[]>} directories список директорий резервной копий
   * @returns {void}
   */
  SET_TREE_DIRECTORY_PATH: (
    state: BackupState,
    directories: Partial<string[]>
  ): void => {
    state.treeDirectoryPath = directories
  },

  /**
   * ADD_TREE_DIRECTORY_PATH
   * ? Добавить директорию резервной копий
   *
   * @param {BackupState} state data
   * @param {string} directoryName выбранная директория резервной копий
   * @returns {void}
   */
  ADD_TREE_DIRECTORY_PATH: (
    state: BackupState,
    directoryName: string
  ): void => {
    state.treeDirectoryPath.push(directoryName)
  },

  /**
   * DELETE_TREE_DIRECTORY_PATH
   * ? Удалить часть пути резервной копий
   *
   * @param {BackupState} state data
   * @param {number} directoryIndex индекс выбранной директории резервной копий
   * @returns {void}
   */
  DELETE_TREE_DIRECTORY_PATH: (state: BackupState, directoryIndex): void => {
    state.treeDirectoryPath = state.treeDirectoryPath.slice(
      0,
      directoryIndex + 1
    )
  },

  /**
   * CLEAR_BACKUP_STATE
   * ? Очистить состояние содержимого бэкапа
   *
   * @param {BackupState} state data
   * @returns {void}
   */
  CLEAR_BACKUP_STATE: (state: BackupState): void => {
    state.treeDirectoryPath = []
    state.currentBackupVersions = []
    state.currentBackupName = ''
    state.currentBackupVersion = ''
  },
}
