import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  ProductsState,
  ProductCategories,
} from '@/store/modules/client/products/types'
import type {
  QuotaResponse,
  ProductResponse,
  ProductsResponse,
} from '@/api/types'
import type { MixCloudLicenses } from '@/types'

import { getFormattedMixCloudLicenses } from '@/helpers/formatted-functions'

type ProductTree = GetterTree<ProductsState, RootState>

export const getters: ProductTree = {
  /**
   * isLoadingProducts
   * ? Загружены ли продукты
   *
   * @param {ProductsState} state data
   * @returns {boolean} признак на то, что продукты загружены
   */
  isLoadingProducts: (state): boolean => {
    return state.isLoadingProducts
  },

  /**
   * hasProducts
   * ? Признак существования продуктов
   *
   * @param {ProductsState} state data
   * @returns {boolean} признак существования продуктов
   */
  hasProducts: (state): boolean => {
    return state.products.length > 0
  },

  /**
   * hasCategoryInProducts
   * ? Есть ли комбинированная категория для продуктов
   *
   * @param {ProductsState} state data
   * @returns {boolean} признак на существование категорий у продуктов
   */
  hasCategoryInProducts: (state: ProductsState): boolean => {
    return state.products.length > 1
  },

  /**
   * isTrialLicense
   * ? Признак триальной версии
   *
   * @param {ProductsState} state data
   * @returns {boolean} признак триальной версии
   */
  isTrialLicense: (state: ProductsState): boolean => {
    return state.products.every(({ sku }) => {
      return (sku.features ?? []).some(({ id }) => id === 'trial')
    })
  },

  /**
   * productKey
   * ? Название ключа для списка продуктов
   *
   * @param {ProductsState} state data
   * @param {object} getters computed
   * @returns {string} название ключа продукта
   */
  productKey: (state: ProductsState, getters): string => {
    return getters.hasCategoryInProducts ? 'categories' : 'products'
  },

  /**
   * productData
   * ? Итоговый список продуктов
   *
   * @param {ProductsState} state data
   * @param {object} getters computed
   * @returns {ProductCategories | ProductsResponse} список категорий продуктов или только список продуктов
   */
  productData: (
    state: ProductsState,
    getters
  ): ProductCategories | ProductsResponse => {
    return getters.hasCategoryInProducts ? state.categories : state.products
  },

  /**
   * selectableExpiringProduct
   * ? Первый или последний продукт с истекающей лицензией
   *
   * @param {ProductsState} state data
   * @returns {ProductResponse | null} первый или последний продукт с истекающей лицензией
   */
  selectableExpiringProduct:
    (state: ProductsState) =>
    ({
      type,
      condition = () => true,
    }: {
      type: string
      condition: (param?: unknown) => boolean
    }) => {
      const types = ['first', 'last']

      if (!types.includes(type)) {
        console.warn(
          `[products/selectableExpiringProduct]: Your type "${type}" is not valid, select from "${types.join(
            ', '
          )}"`
        )

        return null
      }

      let productResult = null
      let currentDate =
        type === 'last' ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY

      to_start: for (const product of state.products) {
        const date = Date.parse(product.expiration_date)

        if (
          (type === 'last' && date > currentDate) ||
          (type === 'first' && date < currentDate)
        ) {
          if (!condition(product)) {
            continue to_start
          }

          currentDate = date
          productResult = product
        }
      }

      return productResult
    },

  /**
   * firstExpiringProductWithCloud
   * ? Первый продукт из списка с клаудом и истекающей лицензией
   *
   * @param {ProductsState} state data
   * @param {object} getter computed
   * @returns {ProductResponse | null} первый продукт с клаудом и истекающей лицензией
   */
  firstExpiringProductWithCloud: (
    state: ProductsState,
    getter
  ): ProductResponse | null => {
    return getter.selectableExpiringProduct({
      type: 'first',
      condition: (product: ProductResponse) => {
        return product.sku.quotas?.some(({ id }) => id === 'cloud')
      },
    })
  },

  /**
   * lastExpiringProduct
   * ? Последний продукт из списка с истекающей лицензией
   *
   * @param {ProductsState} state data
   * @param {object} getter computed
   * @returns {ProductResponse | null} последний продукт с истекающей лицензией
   */
  lastExpiringProduct: (
    state: ProductsState,
    getter
  ): ProductResponse | null => {
    return getter.selectableExpiringProduct({ type: 'last' })
  },

  /**
   * firstExpiringLicenseDate
   * ? Дата первой истекающая лицензия
   *
   * @param {ProductsState} state data
   * @param {object} getters computed
   * @returns {Date | null} дата первой истекающая лицензия
   */
  firstExpiringLicenseDate: (state: ProductsState, getters): Date | null => {
    return (
      getters.cloudQuota?.min_expiration_date ??
      getters.firstExpiringProductWithCloud?.expiration_date ??
      null
    )
  },

  /**
   * lastExpiringLicenseDate
   * ? Дата лицензии с максимальным периодом
   *
   * @param {ProductsState} state data
   * @param {object} getters computed
   * @returns {Date | null} дата лицензии с максимальным периодом
   */
  lastExpiringLicenseDate: (state: ProductsState, getters): Date | null => {
    return getters.lastExpiringProduct
      ? getters.lastExpiringProduct.expiration_date
      : null
  },

  /**
   * lastExpiringLicenseID
   * ? Id лицензии с максимальным периодом
   *
   * @param {ProductsState} state data
   * @param {object} getters computed
   * @returns {Date | null} id лицензии с максимальным периодом
   */
  lastExpiringLicenseID: (state: ProductsState, getters): Date | null => {
    return getters.lastExpiringProduct?.id ?? null
  },

  /**
   * cloudQuota
   * ? Облачная квота
   *
   * @param {ProductsState} state data
   * @returns {QuotaResponse | null} облачная квота
   */
  cloudQuota: (state: ProductsState): QuotaResponse | null => {
    return state.quotas.find(({ quota_id }) => quota_id === 'cloud') ?? null
  },

  /**
   * cloudLicenses
   * ? Объем используемого облака и его лимит (общий объем всех лицензий пользователя)
   *
   * @param {ProductsState} state data
   * @returns {MixCloudLicenses} объем используемого облака и его лимит (общий объем всех лицензий пользователя)
   */
  cloudLicenses: (state: ProductsState): MixCloudLicenses => {
    return getFormattedMixCloudLicenses(state.quotas)
  },
}
