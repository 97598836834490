import type {
  SupportState,
  SupportModule,
} from '@/store/modules/client/support/types'

import { SUPPORT_STATUS } from '@/config/constants'
import { getters } from '@/store/modules/client/support/getters'
import { actions } from '@/store/modules/client/support/actions'
import { mutations } from '@/store/modules/client/support/mutations'
import style from '@/assets/styles/_export-var.module.scss'

const state: SupportState = {
  support: null,
  supports: [],
  isLoading: false,
  isVisibleSupportMenu: false,
  statuses: {
    resolve: {
      list: [SUPPORT_STATUS.SOLVED, SUPPORT_STATUS.CLOSED],
      icon: {
        name: 'circle-success',
        status: 'resolve',
        color: style.colorGreenPrimary,
      },
    },
    reject: {
      list: [SUPPORT_STATUS.DELETED],
      icon: {
        name: 'circle-error',
        status: 'reject',
        color: style.colorRedPrimary,
      },
    },
    pending: {
      list: [
        SUPPORT_STATUS.NEW,
        SUPPORT_STATUS.HOLD,
        SUPPORT_STATUS.OPEN,
        SUPPORT_STATUS.PENDING,
      ],
      icon: {
        name: 'time',
        status: 'pending',
        color: style.colorBluePrimary,
      },
    },
  },
}

export const support: SupportModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
