import type { LoadingRequest } from './types'
import type { StateType } from '@/store/types'

import Vue from 'vue'
import { upperFirstLetter } from '@/helpers'

/**
 * setLoading
 * ? Установить признак загрузки для созданных свойств переданного хранилища
 *
 * @param {StateType} state хранилище
 * @param {LoadingRequest} settings настройки
 * @returns {void}
 */
export const setLoading = (
  state: StateType,
  settings: LoadingRequest
): void => {
  if (!settings.name) {
    console.error(`[SET_LOADING_PROCESS]: set up loading name`)
    return
  }

  const shortName = settings.name
    .split('-')
    .map((name) => upperFirstLetter(name))
    .join('')
  const loadingName = `isLoading${shortName}`

  Vue.set(state, loadingName, settings.loading)

  if (!('groupId' in settings)) {
    return
  }

  // Уникальный id группы (процесс с множественный потоком загрузки)
  const groupId = settings.groupId

  if (typeof groupId === 'number' || typeof groupId === 'string') {
    const groupName = `groupLoading${shortName}`

    Vue.set(state[groupName as keyof StateType], groupId, settings.loading)
  }
}
