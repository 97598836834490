import type { GetterTree } from 'vuex'
import type { LocaleMessage } from 'vue-i18n'
import type { RootState } from '@/store/types'
import type { ProductState } from '@/store/modules/client/product/types'
import type { ProductResponse } from '@/api/types'
type ProductTree = GetterTree<ProductState, RootState>

import { App } from '@/main'
import { differenceInDays, endOfDay, lightFormat } from 'date-fns'

export const getters: ProductTree = {
  /**
   * isAutoRenewalMode
   * ? Признак режима автопродления (определяется на уровне магазина)
   *
   * @returns {boolean} признак режима автопродления
   */
  isAutoRenewalMode: (): ((product: ProductResponse) => boolean) => {
    return (product: ProductResponse): boolean => {
      return product?.subscription?.is_auto ?? false
    }
  },

  /**
   * isAutoRenewalActive
   * ? Признак активности автопродления (определяется на уровне ЛК)
   *
   * @returns {boolean} Признак активности автопродления
   */
  isAutoRenewalActive: (): ((product: ProductResponse) => boolean) => {
    return (product: ProductResponse): boolean => {
      return product?.subscription?.is_active ?? false
    }
  },

  /**
   * isAutoRenewalEnabled
   * ? Признак включенного автопродления (определяется на уровне магазина и ЛК)
   *
   * @param {ProductState} _state data
   * @param {object} getters computed
   * @returns {boolean} признак включенного автопродления
   */
  isAutoRenewalEnabled: (
    _state: ProductState,
    getters
  ): ((product: ProductResponse) => boolean) => {
    return (product: ProductResponse): boolean => {
      return (
        getters.isAutoRenewalMode(product) &&
        getters.isAutoRenewalActive(product)
      )
    }
  },

  /**
   * isAutoRenewalDisabled
   * ? Признак выключенного автопродления (определяется на уровне ЛК)
   *
   * @param {ProductState} _state data
   * @param {object} getters computed
   * @returns {boolean} признак включенного автопродления
   */
  isAutoRenewalDisabled: (
    _state: ProductState,
    getters
  ): ((product: ProductResponse) => boolean) => {
    return (product: ProductResponse): boolean => {
      return (
        getters.isAutoRenewalMode(product) &&
        !getters.isAutoRenewalActive(product)
      )
    }
  },

  /**
   * isPermanentLicense
   * ? Признак постоянной лицензии
   *
   * @returns {boolean} признак постоянной лицензии
   */
  isPermanentLicense: (): ((product: ProductResponse) => boolean) => {
    return (product: ProductResponse): boolean => {
      return product?.sku.ttl === ''
    }
  },

  /**
   * isSubscribeLicense
   * ? Признак постоянной лицензии
   *
   * @param {ProductState} _state data
   * @param {object} getters computed
   * @param {object} _rootState root data
   * @param {object} rootGetters root computed
   * @returns {boolean} признак постоянной лицензии
   */
  isSubscribeLicense: (
    _state: ProductState,
    getters,
    _rootState,
    rootGetters
  ): ((product: ProductResponse) => boolean) => {
    return (product: ProductResponse): boolean => {
      return (
        product !== null &&
        !getters.isPermanentLicense(product) &&
        !rootGetters['products/isTrialLicense']
      )
    }
  },

  /**
   * isMaximumLicensePlan
   * ? Признак максимально выбранной лицензии
   *
   * @returns {boolean} признак максимально выбранной лицензии
   */
  isMaximumLicensePlan: (): ((product: ProductResponse) => boolean) => {
    return (product: ProductResponse): boolean => {
      return product !== null && product.sku.upgrades === undefined
    }
  },

  /**
   * isSubscriptionActive
   * ? Признак активной лицензии (подписки)
   *
   * @returns {boolean} признак активной лицензии (подписки)
   */
  isSubscriptionActive: (): ((product: ProductResponse) => boolean) => {
    return (product: ProductResponse): boolean => {
      if (!product) {
        return false
      }

      const daysLeft = differenceInDays(
        endOfDay(new Date(product.expiration_date)),
        endOfDay(new Date())
      )

      return daysLeft >= 0
    }
  },

  /**
   * subscriptionExpire
   * ? Дата окончания подписки
   *
   * @returns {string} дата окончания подписки
   */
  subscriptionExpire: (): ((product: ProductResponse) => string) => {
    return (product: ProductResponse): string => {
      if (!product || !product.expiration_date) {
        return ''
      }

      return lightFormat(new Date(product.expiration_date), 'dd.MM.yyyy')
    }
  },

  /**
   * subscriptionDaysLeft
   * ? Остаток количества дней у подписки
   *
   * @returns {number} остаток количества дней у подписки
   */
  subscriptionDaysLeft: (): ((product: ProductResponse) => number) => {
    return (product: ProductResponse): number => {
      if (!product || !product.expiration_date) {
        return 0
      }

      return differenceInDays(
        endOfDay(new Date(product.expiration_date)),
        endOfDay(new Date())
      )
    }
  },

  /**
   * subscriptionStatusType
   * ? Тип статуса подписки
   *
   * @param {ProductState} _state data
   * @param {object} getters computed
   * @returns {string | null} Тип статуса подписки
   */
  subscriptionStatusType: (
    _state: ProductState,
    getters
  ): ((product: ProductResponse) => string | null) => {
    return (product: ProductResponse): string | null => {
      if (getters.subscriptionDaysLeft(product) < 0) {
        return 'error'
      }

      if (getters.subscriptionDaysLeft(product) <= 14) {
        return 'warning'
      }

      return null
    }
  },

  /**
   * subscriptionTitle
   * ? Заголовок статуса подписки
   *
   * @param {ProductState} _state data
   * @param {object} getters computed
   * @returns {string | LocaleMessage} Заголовок статуса подписки
   */
  subscriptionTitle: (
    _state: ProductState,
    getters
  ): ((product: ProductResponse) => string | LocaleMessage) => {
    return (product: ProductResponse): string | LocaleMessage => {
      if (getters.subscriptionStatusType(product) === 'error') {
        return getters.isAutoRenewalEnabled(product)
          ? App.$i18n.t('page.products.product.word.not-renewed')
          : App.$i18n.t('page.products.product.word.ended')
      }

      const startText = getters.isAutoRenewalEnabled(product)
        ? App.$i18n.t('page.products.product.word.update')
        : App.$i18n.t('page.products.product.word.sub-until')

      return `${startText} ${getters.subscriptionExpire(product)}`
    }
  },

  /**
   * productName
   * ? Название продукта
   *
   * @returns {string | LocaleMessage} название продукта
   */
  productName: (): ((product: ProductResponse) => string | LocaleMessage) => {
    return (product: ProductResponse | string): string | LocaleMessage => {
      const maps: {
        [key: string]: string | LocaleMessage
      } = {
        'Cyber Backup Personal': App.$i18n.t('product.cyber-backup-personal'),
        default: App.$i18n.t('product.cyber-backup-personal'),
      }

      return typeof product === 'string'
        ? maps[product ?? 'default']
        : maps[product?.sku?.product_id ?? 'default']
    }
  },
}
