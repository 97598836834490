import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { SkusState, IFormatSku } from '@/store/modules/admin/skus/types'

type SkusTree = GetterTree<SkusState, RootState>

import {
  getStatus,
  getFormattedPrice,
  getSubscriptionPeriod,
  getFormattedLicenseType,
  getFormattedCloudLicenseLimit,
  getFormattedLicenseProductName,
  getCloudLicense,
} from '@/helpers/formatted-functions'

export const getters: SkusTree = {
  /**
   * isLoadingCategorySkus
   * ? Процесс загрузки списка sku
   *
   * @param {SkusState} state data
   * @returns {boolean} процесс загрузки списка sku
   */
  isLoadingCategorySkus: (state): boolean => {
    return state.isLoadingCategorySkus
  },

  /**
   * skus
   * ? Список всех sku
   *
   * @param {SkusState} state data
   * @returns {IFormatSku[]} список всех sku
   */
  skus: (state): IFormatSku[] => {
    return state.skus.map((sku) => {
      const { ttl, price, state, quotas, product_id, upgrades } = sku

      return {
        ...sku,
        upgradeSkus: upgrades?.length ?? 0,
        cloudQuotaSoftLimit: getCloudLicense(quotas)?.soft_limit ?? 0,
        formatStatus: getStatus(state),
        formatPrice: getFormattedPrice(price, 2),
        formatPeriod: getSubscriptionPeriod(ttl),
        formatLicenseType: getFormattedLicenseType(ttl),
        formatCloudStorage: getFormattedCloudLicenseLimit(quotas),
        formatProductName: getFormattedLicenseProductName(product_id),
      }
    })
  },
}
