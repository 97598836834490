import type { MutationTree } from 'vuex'
import type { UserState } from '@/store/modules/admin/user/types'
import type {
  MetaResponse,
  OrderResponse,
  QuotaResponse,
  ProductResponse,
  UserBackupArchives,
  AccountActivationType,
} from '@/api/types'
import type {
  IReportUserAccountState,
  IUserOrdersLocalMeta,
  IUserBackupsLocalMeta,
} from '@/store/modules/admin/user/types'

type UserTree = MutationTree<UserState>

import { v4 as uuid } from 'uuid'
import { isEmptyObject } from '@/helpers/checks'
import { setLoading } from '@/helpers/mutation-functions'

export const mutations: UserTree = {
  /**
   * SET_USER
   * ? Установить пользовательский аккаунт
   *
   * @param {UserState} state data
   * @param {IReportUserAccountState} user пользовательский аккаунт
   * @returns {void}
   */
  SET_USER: (state: UserState, user: IReportUserAccountState): void => {
    state.user = user
  },

  /**
   * SET_USER_LICENSES
   * ? Установить пользовательские лицензии
   *
   * @param {UserState} state data
   * @param {Array<ProductResponse>} licenses пользовательские лицензии
   * @returns {void}
   */
  SET_USER_LICENSES: (
    state: UserState,
    licenses: Array<ProductResponse>
  ): void => {
    state.licenses = licenses
  },

  /**
   *  SET_USER_QUOTAS
   * ? Установить пользовательские квоты
   *
   * @param {UserState} state data
   * @param {Array<QuotaResponse>} quotas пользовательские квоты
   * @returns {void}
   */
  SET_USER_QUOTAS: (state: UserState, quotas: Array<QuotaResponse>): void => {
    state.quotas = quotas
  },

  /**
   * SET_USER_ORDERS
   * ? Установить данные ордеров
   *
   * @param {UserState} state data
   * @param {Array<OrderResponse>} orders данные ордеров
   * @returns {void}
   */
  SET_USER_ORDERS: (state: UserState, orders: Array<OrderResponse>): void => {
    state.orders = orders
  },

  /**
   * SET_USER_BACKUPS
   * ? Установить резервные копии пользователя
   *
   * @param {UserState} state data
   * @param {Array<UserBackupArchives>} archives резервные копии пользователя
   * @returns {void}
   */
  SET_USER_BACKUPS: (
    state: UserState,
    archives: Array<UserBackupArchives>
  ): void => {
    state.backups = archives.map((archive) => {
      return {
        id: uuid(),
        ...archive,
      }
    })
  },

  /**
   * SET_ACCOUNT_ACTIVATION_TYPE
   * ? Установить тип активации для аккаунта
   *
   * @param {UserState} state data
   * @param {AccountActivationType | null} activationType тип активации для аккаунта (token, account_id, activation_type)
   * @returns {void}
   */
  SET_ACCOUNT_ACTIVATION_TYPE: (
    state: UserState,
    activationType: AccountActivationType | null
  ): void => {
    state.activationType = activationType
  },

  /**
   * CLEAR_USER_STATE
   * ? Очистить состояние пользователя
   *
   * @param {UserState} state data
   * @returns {void}
   */
  CLEAR_USER_STATE: (state: UserState): void => {
    state.orders = []
    state.quotas = []
    state.backups = []
    state.licenses = []
    state.user = null
    state.activationType = null
  },

  /**
   * SET_USER_ORDERS_META
   * ? Установить мета данные ордеров
   *
   * @param {UserState} state data
   * @param {MetaResponse} meta мета данные ордеров
   * @returns {void}
   */
  SET_USER_ORDERS_META: (state: UserState, meta: MetaResponse): void => {
    state.ordersMeta = meta
  },

  /**
   * SET_USER_BACKUPS_META
   * ? Установить мета данные бэкапов
   *
   * @param {UserState} state data
   * @param {MetaResponse} meta мета данные бэкапов
   * @returns {void}
   */
  SET_USER_BACKUPS_META: (state: UserState, meta: MetaResponse): void => {
    state.backupsMeta = meta
  },

  /**
   * SET_USER_ACTIVE_TAB
   * ? Установить активный таб
   *
   * @param {UserState} state data
   * @param {string} tab активный таб
   * @returns {void}
   */
  SET_USER_ACTIVE_TAB: (state: UserState, tab: string): void => {
    state.activeTab = tab
  },

  /**
   * SET_USER_ORDERS_CURRENT_META
   * ? Установить текущие мета данные ордеров
   *
   * @param {UserState} state data
   * @param {IUserOrdersLocalMeta} meta текущие мета данные
   * @returns {void}
   */
  SET_USER_ORDERS_CURRENT_META: (
    state: UserState,
    meta: IUserOrdersLocalMeta
  ): void => {
    if (isEmptyObject(meta)) {
      state.currentOrdersMeta = structuredClone(state.defaultOrdersMeta)
      return
    }

    state.currentOrdersMeta = { ...state.currentOrdersMeta, ...meta }
  },

  /**
   * SET_USER_BACKUPS_CURRENT_META
   * ? Установить текущие мета данные бэкапов
   *
   * @param {UserState} state data
   * @param {IUserBackupsLocalMeta} meta текущие мета данные
   * @returns {void}
   */
  SET_USER_BACKUPS_CURRENT_META: (
    state: UserState,
    meta: IUserBackupsLocalMeta
  ): void => {
    if (isEmptyObject(meta)) {
      state.currentBackupsMeta = structuredClone(state.defaultBackupsMeta)
      return
    }

    state.currentBackupsMeta = { ...state.currentBackupsMeta, ...meta }
  },

  /**
   * DELETE_USER_BACKUPS
   * ? Удалить резервные копии пользователя
   *
   * @param {UserState} state data
   * @param {Array<string>} backupNames список имён резервных копий пользователя
   * @returns {void}
   */
  DELETE_USER_BACKUPS: (state: UserState, backupNames: string[]): void => {
    state.backups = state.backups.filter(({ name }) => {
      return name && !backupNames.includes(name)
    })
  },

  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,
}
