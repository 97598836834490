import { App } from '@/main'
import { LocaleMessage } from 'vue-i18n'
import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  SupportState,
  StatusItem,
  SupportItem,
  SupportList,
} from '@/store/modules/client/support/types'

import style from '@/assets/styles/_export-var.module.scss'

type SupportTree = GetterTree<SupportState, RootState>

export const getters: SupportTree = {
  /**
   * isLoading
   * ? Статус выполнения запросов
   *
   * @param {SupportState} state data
   * @returns {boolean} isLoading
   */
  isLoading: (state: SupportState): boolean => state.isLoading,

  /**
   * isVisibleSupportMenu
   * ? Открыть/закрыть меню поддержки
   *
   * @param {SupportState} state data
   * @returns {boolean} признак видимости меню поддержки
   */
  isVisibleSupportMenu: (state: SupportState): boolean => {
    return state.isVisibleSupportMenu
  },

  /**
   * iconStatus
   * ? Иконка статуса
   *
   * @param {SupportState} state data
   * @returns {boolean} признак видимости меню поддержки
   */
  iconStatus: (state: SupportState) => {
    type Status = StatusItem | undefined
    type Label = {
      [key: string]: LocaleMessage
    }
    const labels: Label = {
      reject: App.$i18n.t('component.support-menu.history.status-name.reject'),
      pending: App.$i18n.t(
        'component.support-menu.history.status-name.pending'
      ),
      resolve: App.$i18n.t(
        'component.support-menu.history.status-name.resolve'
      ),
      undefined: App.$i18n.t(
        'component.support-menu.history.status-name.undefined'
      ),
    }

    return (status: string) => {
      const current: Status = Object.values(state.statuses).find(({ list }) => {
        return list.includes(status)
      })

      if (!current) {
        return {
          name: 'circle-question',
          label: labels.undefined,
          color: style.colorBluePrimary,
        }
      }

      const label = current.icon.status

      return { ...current.icon, label: labels[label] }
    }
  },

  /**
   * supportList
   * ? Список обращений
   *
   * @param {SupportState} state data
   * @returns {SupportList} список обращений
   */
  supportList: (state: SupportState): SupportList => {
    return state.supports
  },

  /**
   * supportItem
   * ? Детализированный объект обращения
   *
   * @param {SupportState} state data
   * @returns {SupportItem | null} детализированный объект обращения
   */
  supportItem: (state: SupportState): SupportItem | null => {
    return state.support
  },

  /**
   * getProblemType
   * ? Получить тип проблемы обращения
   *
   * @returns {Function} тип проблемы обращения
   */
  getProblemType:
    (): ((type: string) => string) =>
    (type: string): string => {
      type Label = {
        [key: string]: string
      }
      const types: Label = {
        technical_issue: String(
          App.$i18n.t('form.field.value.product-problem')
        ),
        general_questions: String(
          App.$i18n.t('form.field.value.another-problem')
        ),
      }

      return types[type]
    },
}
