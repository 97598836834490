import type { MutationTree } from 'vuex'
import type { Account, AccountState } from '@/store/modules/account/types'
import Vue from 'vue'

type AccountTree = MutationTree<AccountState>

import { setLoading } from '@/helpers/mutation-functions'

export const mutations: AccountTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_USER_EMAIL
   * ? Установить почту
   *
   * @param {AccountState} state data
   * @param {string} email почта от аккаунта
   * @returns {void}
   */
  SET_USER_EMAIL: (state: AccountState, email: string): void => {
    state.email = email
  },

  /**
   * SET_USER_PASSWORD
   * ? Установить пароль
   *
   * @param {AccountState} state data
   * @param {string} password пароль от аккаунта
   * @returns {void}
   */
  SET_USER_PASSWORD: (state: AccountState, password: string): void => {
    state.password = password
  },

  /**
   * SET_USER_FIRST_NAME
   * ? Установить имя пользователя
   *
   * @param {AccountState} state data
   * @param {string} name имя пользователя
   * @returns {void}
   */
  SET_USER_FIRST_NAME: (state: AccountState, name: string): void => {
    const account = state.account

    if (account) {
      Vue.set(account, 'first_name', name)
    }
  },

  /**
   * SET_USER_LAST_NAME
   * ? Установить фамилию пользователя
   *
   * @param {AccountState} state data
   * @param {string} lastName фамилия пользователя
   * @returns {void}
   */
  SET_USER_LAST_NAME: (state: AccountState, lastName: string): void => {
    const account = state.account

    if (account) {
      Vue.set(account, 'last_name', lastName)
    }
  },

  /**
   * SET_DATE_OF_PASSWORD_CHANGE
   * ? Установить дату изменения пароля
   *
   * @param {AccountState} state data
   * @param {string} date дата изменения пароля
   * @returns {void}
   */
  SET_DATE_OF_PASSWORD_CHANGE: (state: AccountState, date: string): void => {
    const account = state.account

    if (account) {
      Vue.set(account, 'password_modification_date', date)
    }
  },

  /**
   * SET_USER_PHONE
   * ? Установить пароль
   *
   * @param {AccountState} state data
   * @param {string} phone телефон пользователя
   * @returns {void}
   */
  SET_USER_PHONE: (state: AccountState, phone: string): void => {
    const account = state.account

    if (account) {
      Vue.set(account, 'phone', phone)
    }
  },

  /**
   * SET_USER_ACCOUNT
   * ? Установить аккаунт
   *
   * @param {AccountState} state data
   * @param {Account} account свойства аккаунта
   * @returns {void}
   */
  SET_USER_ACCOUNT: (state: AccountState, account: Account): void => {
    state.account = account

    if (account?.phone) {
      Vue.set(
        account,
        'phone',
        // Формирую пробелы перед и после скобок телефонного номера
        account.phone.replace(/\(/, ' (').replace(/\)/, ') ')
      )
    }
  },

  /**
   * SET_ACCOUNT_DELETE
   * ? Установить признак удалившегося аккаунта
   *
   * @param {AccountState} state data
   * @param {boolean} deleted признак удалившегося аккаунта
   * @returns {void}
   */
  SET_ACCOUNT_DELETE: (state: AccountState, deleted): void => {
    state.isAccountDeleted = deleted
  },

  /**
   * SET_IS_ENABLED_BUSINESS_REGISTER
   * ? Установить признак активности регистрации бизнес аккаунтов
   *
   * @param {AccountState} state data
   * @param {boolean} isEnabled признак активности регистрации бизнес аккаунтов
   * @returns {void}
   */
  SET_IS_ENABLED_BUSINESS_REGISTER: (state: AccountState, isEnabled): void => {
    state.isEnabledRegisterBusiness = isEnabled
  },

  /**
   * SET_SUBSCRIPTION_STATUS
   * ? Установить состояние подписки на рассылку
   *
   * @param {AccountState} state data
   * @param {boolean} subscribe признак подписки на рассылку
   * @returns {void}
   */
  SET_SUBSCRIPTION_STATUS: (state: AccountState, subscribe): void => {
    const account = state.account

    if (account) {
      Vue.set(account, 'is_subscribed', subscribe)
    }
  },
}
