import type { UsersState, UsersModule } from '@/store/modules/admin/users/types'

import { getters } from '@/store/modules/admin/users/getters'
import { actions } from '@/store/modules/admin/users/actions'
import { mutations } from '@/store/modules/admin/users/mutations'

const getUsersMeta = () => {
  return {
    total: 100,
    maxPages: 10,
    meta: {
      paging: {
        page: 1,
        limit: 10,
      },
      sort: {
        direction: 'desc',
        order_by: 'created_date',
      },
    },
  }
}

const getReportUsersMeta = () => {
  return {
    total: 100,
    maxPages: 10,
    meta: {
      paging: {
        page: 1,
        limit: 20,
      },
      sort: {
        direction: 'desc',
        order_by: 'created_date',
      },
    },
  }
}

const state: UsersState = {
  users: [],
  reportUsers: [],
  deletedAccountIds: [],
  currentUsersMeta: getUsersMeta(),
  defaultUsersMeta: getUsersMeta(),
  currentReportUsersMeta: getReportUsersMeta(),
  defaultReportUsersMeta: getReportUsersMeta(),
  isLoadingUsers: false,
  isLoadingReportUsers: false,
  isLoadingExportUsers: false,
  isLoadingDeleteAccounts: false,
  isLoadingDeleteAllBackups: false,
}

export const users: UsersModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
