import type { RootState } from '@/store/types'
import type { ActionTree, ActionContext } from 'vuex'
import type { OrderState } from '@/store/modules/admin/order/types'

type OrderTree = ActionTree<OrderState, RootState>
type OrderContext = ActionContext<OrderState, RootState>

import api from '@/api'

export const actions: OrderTree = {
  /**
   * setOrder
   * ? Установить все связанные данные с ордером
   *
   * @param {OrderContext} ctx context
   * @param {string} orderId идентификатор ордера
   * @returns {Promise<undefined[] | null>} без данных
   */
  setOrder: (
    { state, commit, dispatch }: OrderContext,
    orderId: string
  ): Promise<undefined[] | null> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'all-data-order' })

    return dispatch('fetchCurrentOrder', orderId)
      .then(() => {
        if (state.order === null) {
          return null
        }

        const { account_id, license_id, delivered_license_id } = state.order
        const licenseId = license_id || delivered_license_id

        return Promise.all([
          dispatch('fetchCurrentUser', account_id),
          dispatch('fetchCurrentLicense', licenseId),
        ])
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          name: 'all-data-order',
          loading: false,
        })
      })
  },

  /**
   * fetchCurrentOrder
   * ? Извлечь текущий ордер
   *
   * @param {OrderContext} ctx context
   * @param {string} orderId идентификатор ордера
   * @returns {Promise<void>}
   */
  fetchCurrentOrder: (
    { commit }: OrderContext,
    orderId: string
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'order' })

    return api.lk
      .getCurrentOrder(orderId)
      .then((order) => commit('SET_CURRENT_ORDER', order))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'order',
        })
      })
  },

  /**
   * fetchCurrentLicense
   * ? Извлечь текущую лицензию ордера
   *
   * @param {OrderContext} ctx context
   * @param {string} licenseId идентификатор лицензии
   * @returns {Promise<void>}
   */
  fetchCurrentLicense: (
    { commit, dispatch }: OrderContext,
    licenseId: string
  ): Promise<void> => {
    if (licenseId === undefined) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'license' })

    return api.lk
      .geCurrentLicense(licenseId)
      .then((license) => {
        commit('SET_CURRENT_LICENSE', license)

        const { sku } = license
        return dispatch('fetchCurrentSku', sku)
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'license',
        })
      })
  },

  /**
   * fetchCurrentUser
   * ? Извлечь текущий аккаунт лицензии
   *
   * @param {OrderContext} ctx context
   * @param {string} accountId идентификатор аккаунта
   * @returns {Promise<void>}
   */
  fetchCurrentUser: (
    { commit }: OrderContext,
    accountId: string
  ): Promise<void> => {
    if (accountId === undefined) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'user-account' })

    return api.lk
      .getUserAccount(accountId)
      .then((user) => commit('SET_CURRENT_USER', user))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'user-account',
        })
      })
  },

  /**
   * fetchCurrentSku
   * ? Извлечь текущий sku
   *
   * @param {OrderContext} ctx context
   * @param {string} skuId идентификатор sku
   * @returns {Promise<void>}
   */
  fetchCurrentSku: ({ commit }: OrderContext, skuId: string): Promise<void> => {
    if (skuId === undefined) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'sku' })

    return api.lk
      .getCurrentSku(skuId)
      .then((sku) => commit('SET_CURRENT_SKU', sku))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'sku',
        })
      })
  },
}
