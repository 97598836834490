import type {
  SkuPayload,
  SkuResponse,
  SkusPayload,
  OrderPayload,
  OrderResponse,
  QuotaResponse,
  SupportPayload,
  ProductPayload,
  AccountResponse,
  ActivatePayload,
  ProductResponse,
  SubscribePayload,
  ProductsResponse,
  IFeatureResponse,
  AutoRenewOptions,
  PostOrderRequest,
  OrdersDataResponse,
  TUpgradeSkuPayload,
  OkDeskListResponse,
  OkDeskItemResponse,
  GenLicensesRequest,
  IAddCampaignPayload,
  GenLicensesResponse,
  StorageInfoResponse,
  IEditCampaignPayload,
  AccountUpdatePayload,
  AccountDeletePayload,
  RevokeLicensePayload,
  IUserAccountsPayload,
  AssignLicensesRequest,
  RevokeLicenseResponse,
  ICampaignsDataResponse,
  TransferLicensePayload,
  IUserAccountsResponse,
  ProductsUpgradeResponse,
  IExternalProductResponse,
  IExternalLicensesPayload,
  IRegisterAccountResponse,
  IDeactivateMachinePayload,
  ActivatedMachinesResponse,
  IExternalLicensesResponse,
  SubscriptionRenewalRequest,
  SubscriptionRenewalResponse,
  IPersonalRegisterAccountPayload,
  IBusinessRegisterAccountPayload,
  IExternalLicensesActivateTrialPayload,
  IExternalLicensesActivateTrialRequest,
} from '@/api/services/lk/types'

import type { ICampaignsPayload, IReportCampaignItem } from '@/api/types'

import axios from '@/api/axios'

export default {
  /** ==== Account ==== */

  /**
   * accountActivateWithForm
   * ? Активация аккаунта с помощью формы (регистрация с внешнего ресурса)
   *
   * @param {ActivatePayload} payload форма для активации аккаунта
   * @returns {Promise<void>}
   */
  accountActivateWithForm(payload: ActivatePayload): Promise<void> {
    return axios.post('/api/account/v1/account:activate_and_complete', payload)
  },

  /**
   * registerPersonalAccount
   * ? Регистрация клиентского аккаунта
   *
   * @param {IPersonalRegisterAccountPayload} payload форма для регистрации клиентского аккаунта
   * @returns {Promise<IRegisterAccountResponse>} идентификатор клиентского аккаунта
   */
  registerPersonalAccount(
    payload: IPersonalRegisterAccountPayload
  ): Promise<IRegisterAccountResponse> {
    return axios.post('/api/account/v1/customer_accounts', payload)
  },

  /**
   * registerBusinessAccount
   * ? Регистрация бизнес аккаунта
   *
   * @param {IBusinessRegisterAccountPayload} payload форма для регистрации бизнес аккаунта
   * @returns {Promise<IRegisterAccountResponse>} идентификатор бизнес аккаунта
   */
  registerBusinessAccount(
    payload: IBusinessRegisterAccountPayload
  ): Promise<IRegisterAccountResponse> {
    return axios.post('/api/account/v1/business_accounts', payload)
  },

  /**
   * getIsEnabledBusinessRegister
   * ? Включена ли регистрация бизнес аккаунта на площадке
   *
   * @returns {Promise<boolean>} Включена ли регистрация бизнес аккаунта на площадке
   */
  getIsEnabledBusinessRegister(): Promise<boolean> {
    return axios.get('/api/system/v1/lk_cb_enabled')
  },

  /**
   * getCurrentAccount
   * ? Получить текущий аккаунт
   *
   * @returns {Promise<AccountResponse>} свойства аккаунта
   */
  getCurrentAccount(): Promise<AccountResponse> {
    return axios.get('/api/account/v1/accounts/me')
  },

  /**
   * getUserAccount
   * ? Получить конкретный аккаунт пользователя
   *
   * @param {string} id идентификатор пользователя
   * @returns {Promise<AccountResponse>} конкретный аккаунт пользователя
   */
  getUserAccount(id: string): Promise<AccountResponse> {
    return axios.get(`/api/account/v1/accounts/${id}`)
  },

  /**
   * getUserAccounts
   * ? Получить список аккаунтов пользователей
   *
   * @param {IUserAccountsPayload} params параметры конфигурирования списка пользователей
   * @returns {Promise<IUserAccountsResponse>} список аккаунтов пользователей
   */
  getUserAccounts(
    params: IUserAccountsPayload
  ): Promise<IUserAccountsResponse> {
    return axios.get(`/api/account/v1/accounts`, { params })
  },

  /**
   * getUrlWebRestore
   * ? Получить актуальный url Web Restore
   *
   * @returns {Promise<StorageInfoResponse>} url web restore
   */
  getUrlWebRestore(): Promise<StorageInfoResponse> {
    return axios.get('/api/account/v1/storage:info')
  },

  /**
   * updateAccount
   * ? Обновить данные о пользователе
   *
   * @param {AccountUpdatePayload} payload форма изменения информации о пользователе
   * @returns { Promise<void>}
   */
  updateAccount(payload: AccountUpdatePayload): Promise<void> {
    const { account_id, data } = payload
    return axios.put(`/api/account/v1/accounts/${account_id}`, data)
  },

  /**
   * switchSubscribe
   * ? Подписаться/отписаться на рассылку
   *
   * @param {SubscribePayload} payload опции запроса
   * @returns { Promise<void>}
   */
  switchSubscribe(payload: SubscribePayload): Promise<void> {
    const { account_id, data } = payload
    return axios.put(
      `/api/account/v1/accounts/${account_id}/marketing_notifications`,
      data
    )
  },

  /**
   * deleteAccount
   * ? Удалить аккаунт
   *
   * @param {AccountDeletePayload} payload форма удаления профиля
   * @returns { Promise<void>}
   */
  deleteAccount(payload: AccountDeletePayload): Promise<void> {
    const { account_id, data } = payload
    return axios.delete(`/api/account/v1/accounts/${account_id}`, {
      data,
    })
  },

  /**
   * deleteAccounts
   * ? Удалить список аккаунтов
   *
   * @param {string} ids идентификаторы аккаунтов (ids в виде строки через запятую)
   * @returns { Promise<void>}
   */
  deleteAccounts(ids: string): Promise<void> {
    const params = { ids }
    return axios.delete('/api/account/v1/accounts', { params })
  },

  /** ==== Market ==== */

  /**
   * getCampaign
   * ? Получить кампанию
   *
   * @param {string} id идентификатор текущей кампании
   * @returns {Promise<IReportCampaignItem>} текущая кампания
   */
  getCampaign(id: string): Promise<IReportCampaignItem> {
    return axios.get(`/api/market/v1/campaigns/${id}`)
  },

  /**
   * getCampaigns
   * ? Получить список кампаний
   *
   * @param {ICampaignsPayload} params параметры конфигурации запроса кампаний
   * @returns {Promise<ICampaignsDataResponse>} список кампаний
   */
  getCampaigns(params: ICampaignsPayload): Promise<ICampaignsDataResponse> {
    return axios.get('/api/market/v1/campaigns', { params })
  },

  /**
   * addCampaign
   * ? Добавить кампанию
   *
   * @param {IAddCampaignPayload} payload параметры добавления кампании
   * @returns {Promise<void>}
   */
  addCampaign(payload: IAddCampaignPayload): Promise<void> {
    return axios.post('/api/market/v1/campaigns', payload)
  },

  /**
   * archiveCampaign
   * ? Архивировать кампанию
   *
   * @param {string} id идентификатор текущей кампании
   * @returns {Promise<void>}
   */
  archiveCampaign(id: string): Promise<void> {
    return axios.post(`/api/market/v1/campaigns/${id}:archive`)
  },

  /**
   * publishCampaign
   * ? Опубликовать кампанию
   *
   * @param {string} id идентификатор текущей кампании
   * @returns {Promise<void>}
   */
  publishCampaign(id: string): Promise<void> {
    return axios.post(`/api/market/v1/campaigns/${id}:publish`)
  },

  /**
   * editCampaign
   * ? Редактировать кампанию
   *
   * @param {IEditCampaignPayload} payload параметры запроса для текущей кампании
   * @returns {Promise<void>}
   */
  editCampaign({ id, data }: IEditCampaignPayload): Promise<void> {
    return axios.put(`/api/market/v1/campaigns/${id}`, data)
  },

  /**
   * deleteCampaign
   * ? Удалить кампанию
   *
   * @param {string} id идентификатор текущей кампании
   * @returns {Promise<void>}
   */
  deleteCampaign(id: string): Promise<void> {
    return axios.delete(`/api/market/v1/campaigns/${id}`)
  },

  /**
   * getCurrentOrder
   * ? Получить текущий заказ
   *
   * @param {string} id идентификатор ордера
   * @returns {Promise<OrderResponse>} текущий заказ данные
   */
  getCurrentOrder(id: string): Promise<OrderResponse> {
    return axios.get(`/api/market/v1/orders/${id}`)
  },

  /**
   * getOrders
   * ? Получить список заказов
   *
   * @param {OrderPayload} params опции выборки для заказа
   * @returns {Promise<OrdersDataResponse>} список заказов и мета данные
   */
  getOrders(params: OrderPayload): Promise<OrdersDataResponse> {
    return axios.get('/api/market/v1/orders', { params })
  },

  /**
   * orderCreate
   * ? Обработка заказа лицензией и подписки
   * 'orders.license.new' - купить новую лицензию
   * 'orders.license.upgrade' - обновить существующую лицензию
   * 'orders.subscription.renew' - продлить подписку
   *
   * @param {SubscriptionRenewalRequest} payload идентификатор лицензии
   * @returns {Promise<SubscriptionRenewalResponse>} Объект, с сгенерированной ссылкой в магазин
   */
  orderCreate(
    payload: SubscriptionRenewalRequest
  ): Promise<SubscriptionRenewalResponse> {
    return axios.post('/api/market/v1/orders:store', payload)
  },

  /**
   * generateOrdersCampaign
   * ? Сгенерировать ордера кампании
   *
   * @param {PostOrderRequest} payload параметры ордера кампании
   * @returns {Promise<OrderResponse>} сгенерированный ордер
   */
  generateOrdersCampaign(payload: PostOrderRequest): Promise<OrderResponse> {
    return axios.post('/api/market/v1/orders:campaign', payload)
  },

  /**
   * getFeatureList
   * ? Получить список возможностей
   *
   * @returns {Promise<IFeatureResponse>} список возможностей
   */
  getFeatureList(): Promise<IFeatureResponse[]> {
    return axios.get('/api/market/v1/features')
  },

  /**
   * getCurrentSku
   * ? Получить текущий SKU
   *
   * @param {string} id идентификатор SKU
   * @returns {Promise<SkuResponse>} текущий SKU
   */
  getCurrentSku(id: string): Promise<SkuResponse> {
    return axios.get(`/api/market/v1/skus/${id}`)
  },

  /**
   * getListSku
   * ? Получить список всех SKU
   *
   * @param {Partial<SkusPayload>} params дополнительные параметры для более расширенного списка данных
   * @returns {Promise<Array<SkuResponse>>} список всех SKU
   */
  getListSku(params: Partial<SkusPayload> = {}): Promise<Array<SkuResponse>> {
    return axios.get('/api/market/v1/skus', { params })
  },

  /**
   * createSku
   * ? Создать новый SKU
   *
   * @param {SkuPayload} payload параметры SKU
   * @returns {Promise<void>}
   */
  createSku(payload: SkuPayload): Promise<void> {
    return axios.post('/api/market/v1/skus', payload)
  },

  /**
   * editSku
   * ? Редактировать SKU
   *
   * @param {SkuPayload} payload параметры SKU
   * @returns {Promise<void>}
   */
  editSku(payload: SkuPayload): Promise<void> {
    return axios.put(`/api/market/v1/skus/${payload.id}`, payload)
  },

  /**
   * editPriceSku
   * ? Редактировать цену SKU
   *
   * @param {string} id идентификатор SKU
   * @param {number} price цена SKU
   * @returns {Promise<void>}
   */
  editPriceSku(id: string, price: number): Promise<void> {
    return axios.put(`/api/market/v1/skus/${id}/price`, { price })
  },

  /**
   * editUpgradesSku
   * ? Редактировать апгрейды SKU
   *
   * @param {string} id идентификатор SKU
   * @param {TUpgradeSkuPayload} payload список SKU для апгрейда
   * @returns {Promise<void>}
   */
  editUpgradesSku(id: string, payload: TUpgradeSkuPayload): Promise<void> {
    return axios.put(`/api/market/v1/skus/${id}/upgrades`, payload)
  },

  /**
   * deleteSku
   * ? Удалить SKU
   *
   * @param {string} id идентификатор SKU
   * @returns {Promise<void>}
   */
  deleteSku(id: string): Promise<void> {
    return axios.delete(`/api/market/v1/skus/${id}`)
  },

  /**
   * archiveSku
   * ? Архивировать SKU
   *
   * @param {string} id идентификатор SKU
   * @returns {Promise<void>}
   */
  archiveSku(id: string): Promise<void> {
    return axios.post(`/api/market/v1/skus/${id}:archive`)
  },

  /**
   * publishSku
   * ? Опубликовать SKU
   *
   * @param {SkuPayload} sku параметры SKU для публикации
   * @returns {Promise<void>}
   */
  async publishSku(sku: SkuPayload): Promise<void> {
    return axios.post(`/api/market/v1/skus/${sku.id}:publish`)
  },

  /** ==== CustomerService ==== */

  /**
   * createSupport
   * ? Создать обращение в техподдержку
   *
   * @param {SupportPayload} payload форма поддержки
   * @returns {Promise<void>}
   */
  createSupport(payload: SupportPayload): Promise<void> {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    return axios.post('/api/customer_service/v1/issues', payload, config)
  },

  /**
   * getSupportList
   * ? Получить список обращений в техподдержку (неполный)
   *
   * @returns {Promise<OkDeskListResponse>} список обращений
   */
  getSupportList(): Promise<OkDeskListResponse> {
    return axios.get('/api/customer_service/v1/issues')
  },

  /**
   * getSupportItem
   * ? Получить детализированный объект обращения в техподдержку
   *
   * @param {string} id идентификатор обращения
   * @returns {Promise<OkDeskItemResponse>} детализированный объект обращения
   */
  getSupportItem(id: string): Promise<OkDeskItemResponse> {
    return axios.get(`/api/customer_service/v1/issues/${id}`)
  },

  /** ==== License ==== */

  /**
   * getExternalLicenses
   * ? Получить список внешних лицензий для текущего аккаунта
   *
   * @param {IExternalLicensesPayload} params параметры запроса внешних лицензий
   * @returns {Promise<IExternalLicensesResponse>} список внешних лицензий аккаунта
   */
  getExternalLicenses(
    params: IExternalLicensesPayload
  ): Promise<IExternalLicensesResponse> {
    return axios.get('/api/licensing/v1/external_licenses', { params })
  },

  /**
   * getExternalProduct
   * ? Получить внешний продукт по его идентификатору
   *
   * @param {string} id идентификатор продукта
   * @returns {Promise<IExternalProductResponse>} внешний продукт
   */
  getExternalProduct(id: string): Promise<IExternalProductResponse> {
    return axios.get(`/api/market/v1/external_products/${id}`)
  },

  /**
   * activateTrialLicense
   * ? Активировать пробную версию для бизнес аккаунта
   *
   * @param {IExternalLicensesActivateTrialPayload} data данные запроса на активацию пробной версии
   * @returns {Promise<IExternalLicensesActivateTrialRequest>} идентификатор активированного продукта
   */
  activateTrialLicense(
    data: IExternalLicensesActivateTrialPayload
  ): Promise<IExternalLicensesActivateTrialRequest> {
    return axios.post(
      '/api/licensing/v1/external_licenses:activate_trial',
      data
    )
  },

  /**
   * getAccountProducts
   * ? Получить список продуктов для текущего аккаунта
   *
   * @param {ProductPayload} params параметры запроса продуктов
   * @returns {Promise<ProductsResponse>} список продуктов аккаунта
   */
  getAccountProducts(params: ProductPayload): Promise<ProductsResponse> {
    return axios.get('/api/licensing/v1/licenses', { params })
  },

  /**
   * geCurrentLicense
   * ? Получить текущую лицензию
   *
   * @param {string} id идентификатор лицензии
   * @returns {Promise<ProductResponse>} получить текущую лицензию
   */
  geCurrentLicense(id: string): Promise<ProductResponse> {
    return axios.get(`/api/licensing/v1/licenses/${id}`)
  },

  /**
   * getActivatedMachines
   * ? Получить список активных машин
   *
   * @param {string} id идентификатор лицензии
   * @returns {Promise<ActivatedMachinesResponse>} список активированных машин
   */
  getActivatedMachines(id: string): Promise<ActivatedMachinesResponse> {
    return axios.get(`/api/licensing/v1/licenses/${id}/activations`)
  },

  /**
   * getProductsToUpgrade
   * ? Получить подходящий список продуктов для обновления
   *
   * @param {string} id идентификатор лицензии
   * @returns {Promise<ProductsUpgradeResponse>} список продуктов для обновления
   */
  getProductsToUpgrade(id: string): Promise<ProductsUpgradeResponse> {
    return axios.get(`/api/licensing/v1/licenses/${id}/upgrade_options`)
  },

  /**
   * getAccountQuotas
   * ? Получить список квот
   *
   * @param {string} id идентификатор аккаунта
   * @returns {Promise<Array<QuotaResponse>>} список квот
   */
  getAccountQuotas(id: string): Promise<Array<QuotaResponse>> {
    const params = {
      account_id: id,
    }

    return axios.get('/api/licensing/v1/quotas', { params })
  },

  /**
   * setAutoRenewal
   * ? Активировать/деактивировать авто продление подписки
   *
   * @param {AutoRenewOptions} payload опции AutoRenewOptions
   * @returns {Promise<void>}
   */
  setAutoRenewal(payload: AutoRenewOptions): Promise<void> {
    const { id, isActivate } = payload
    const action = isActivate ? 'activate_sub' : 'deactivate_sub'

    return axios.post(`/api/licensing/v1/licenses/${id}:${action}`)
  },

  /**
   * deactivateMachine
   * ? Деактивировать машину
   *
   * @param {IDeactivateMachinePayload} options идентификаторы машины
   * @returns {Promise<void>}
   */
  deactivateMachine({
    id,
    agent_id,
  }: IDeactivateMachinePayload): Promise<void> {
    const data = { agent_id }
    return axios.post(`/api/licensing/v1/licenses/${id}:deactivate`, data)
  },

  /**
   * assignLicenses
   * ? Назначить лицензию на аккаунт
   *
   * @param {AssignLicensesRequest} payload опции запроса
   * @returns {Promise<void>}
   */
  assignLicenses(payload: AssignLicensesRequest): Promise<void> {
    return axios.post('/api/licensing/v1/licenses:assign', payload)
  },

  /**
   * transferLicense
   * ? Перенести лицензию на другой аккаунт
   *
   * @param {TransferLicensePayload} payload параметры запроса переноса лицензии(ий)
   * @returns {Promise<void>}
   */
  transferLicense(payload: TransferLicensePayload): Promise<void> {
    return axios.post('/api/licensing/v1/licenses:move', payload)
  },

  /**
   * revokeLicense
   * ? Отозвать лицензию в аккаунте
   *
   * @param {RevokeLicensePayload} payload параметры запроса удаления лицензии(ий)
   * @returns {Promise<Array<RevokeLicenseResponse>>} результат отозванной лицензии
   */
  revokeLicense(
    payload: RevokeLicensePayload
  ): Promise<Array<RevokeLicenseResponse>> {
    return axios.post('/api/licensing/v1/licenses:revoke', payload)
  },

  /**
   * generateLicenses
   * ? Сгенерировать новый лицензии
   *
   * @param {GenLicensesRequest} payload параметры генерации лицензии
   * @returns {Promise<GenLicensesResponse>} список сгенерированных лицензий
   */
  generateLicenses(payload: GenLicensesRequest): Promise<GenLicensesResponse> {
    return axios.post('/api/licensing/v1/licenses:generate', payload)
  },
}
