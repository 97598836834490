import type { RootState } from '@/store/types'
import type { ActionTree, ActionContext } from 'vuex'
import type { LicenseState } from '@/store/modules/admin/license/types'

type LicenseTree = ActionTree<LicenseState, RootState>
type LicenseContext = ActionContext<LicenseState, RootState>

import api from '@/api'
import hasPermission from '@/helpers/permissions'

export const actions: LicenseTree = {
  /**
   * setLicense
   * ? Установить все данные лицензии
   *
   * @param {LicenseContext} ctx context
   * @param {string} id идентификатор лицензии
   * @returns {Promise<undefined[] | null>} без данных
   */
  setLicense: (
    { state, commit, dispatch }: LicenseContext,
    id: string
  ): Promise<undefined[] | null> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'all-data-license' })

    return dispatch('fetchCurrentLicense', id)
      .then(() => {
        if (state.license === null) {
          return null
        }

        const { sku, order_id, account_id } = state.license

        return Promise.all([
          dispatch('fetchCurrentSku', sku),
          dispatch('fetchCurrentUser', account_id),
          dispatch('fetchCurrentOrder', order_id),
        ]).then(() => {
          const campaignId = state.order?.campaign_id ?? null

          if (!campaignId) {
            return Promise.resolve()
          }

          return dispatch('fetchCurrentCampaign', campaignId)
        })
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          name: 'all-data-license',
          loading: false,
        })
      })
  },

  /**
   * fetchCurrentLicense
   * ? Извлечь текущую лицензию
   *
   * @param {LicenseContext} ctx context
   * @param {string} licenseId идентификатор лицензии
   * @returns {Promise<void>}
   */
  fetchCurrentLicense: (
    { commit }: LicenseContext,
    licenseId: string
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'license' })

    return api.lk
      .geCurrentLicense(licenseId)
      .then((license) => commit('SET_CURRENT_LICENSE', license))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'license',
        })
      })
  },

  /**
   * fetchCurrentUser
   * ? Извлечь текущий аккаунт
   *
   * @param {LicenseContext} ctx context
   * @param {string} accountId идентификатор аккаунта
   * @returns {Promise<void>}
   */
  fetchCurrentUser: (
    { commit }: LicenseContext,
    accountId: string
  ): Promise<void> => {
    if (accountId === undefined) {
      return Promise.resolve()
    }
    commit('SET_LOADING_PROCESS', { loading: true, name: 'user-account' })

    return api.lk
      .getUserAccount(accountId)
      .then((user) => commit('SET_CURRENT_USER', user))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'user-account',
        })
      })
  },

  /**
   * fetchCurrentOrder
   * ? Извлечь текущий ордер
   *
   * @param {LicenseContext} ctx context
   * @param {string} orderId идентификатор ордера
   * @returns {Promise<void>}
   */
  fetchCurrentOrder: (
    { commit }: LicenseContext,
    orderId: string
  ): Promise<void> => {
    if (orderId === undefined || !hasPermission('GET_MARKET_V1_ORDERS')) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'order' })

    return api.lk
      .getCurrentOrder(orderId)
      .then((order) => commit('SET_CURRENT_ORDER', order))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'order',
        })
      })
  },

  /**
   * fetchCurrentSku
   * ? Извлечь текущий sku
   *
   * @param {LicenseContext} ctx context
   * @param {string} skuId идентификатор sku
   * @returns {Promise<void>}
   */
  fetchCurrentSku: (
    { commit }: LicenseContext,
    skuId: string
  ): Promise<void> => {
    if (skuId === undefined) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'sku' })

    return api.lk
      .getCurrentSku(skuId)
      .then((sku) => commit('SET_CURRENT_SKU', sku))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'sku',
        })
      })
  },

  /**
   * fetchCurrentCampaign
   * ? Извлечь текущую кампанию
   *
   * @param {LicenseContext} ctx context
   * @param {string} id идентификатор текущей кампании
   * @returns {Promise<void>}
   */
  fetchCurrentCampaign: (
    { commit }: LicenseContext,
    id: string
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'campaign' })

    return api.lk
      .getCampaign(id)
      .then((campaign) => commit('SET_CURRENT_CAMPAIGN', campaign))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'campaign',
        })
      })
  },
}
