import type {
  TByteSize,
  SimpleKeys,
  TMetaGroups,
  TByteConversion,
  TActualMetaGroups,
  DecodedOptionsQuery,
} from './types'

import { App } from '@/main'
import { isEmptyObject } from '@/helpers/checks'
import { getItemStorage } from './local-storage'
import { LOCALE, QUERY_KEYS, TABLE_TYPE } from '@/config/constants'

/**
 * reloadPage
 * ? Перезагрузить страницу
 *
 * @returns {void}
 */
export const reloadPage = () => location.reload()

/**
 * setMetaTitle
 * ? Установить название вкладки браузера
 *
 * @param {?string} title название вкладки браузера
 */
export const setMetaTitle = (title?: string) => {
  document.title = title || String(App.$i18n.t('app.meta.title'))
}

/**
 * convertToBytes
 * ? Преобразовать число в байты (исходя из переданных единиц измерения)
 *
 * @param {number} value значение
 * @param {string} unit единица измерения
 * @param {boolean} isMathRound математическое округление
 * @returns {string} высчитанная величина
 */
export const convertToBytes = (
  value: number,
  unit: string,
  isMathRound = true
): number => {
  const unitDegrees: { [key: string]: number } = { mb: 2, gb: 3, tb: 4 }
  const degree = unitDegrees[unit]

  const bytes = Math.pow(1024, degree) * value

  return isMathRound ? Math.round(bytes) : bytes
}

/**
 * convertToGigaBytes
 * ? Преобразовать число в гагабайты (исходя из переданных единиц измерения)
 *
 * @param {number} value значение
 * @param {'gb' | 'tb' | 'mb'} unit единица измерения
 * @param {boolean} isMathRound математическое округление
 * @returns {number} высчитанная величина
 */
export const convertToGigaBytes = (
  value: number,
  unit: TByteSize,
  isMathRound = true
): number => {
  if (unit === 'gb') {
    return value
  }

  return convertToBytes(value, unit, isMathRound) / Math.pow(1024, 3)
}

/**
 * convertBytes
 * ? Преобразует переданное значение в байтах в соответствующий размер исходя из количества байтов.
 *
 * @param {number} value значение в байтах
 * @param {number} precision точность
 * @returns {TByteConversion} преобразованная величина, тип преобразованной величины
 */
export const convertBytes = (value: number, precision = 0): TByteConversion => {
  const byteSizes: TByteSize[] = [
    'b',
    'kb',
    'mb',
    'gb',
    'tb',
    'pb',
    'eb',
    'zb',
    'yb',
  ]

  if (Math.abs(value) < 1) {
    return [value, byteSizes[0]]
  }

  const k = 1024
  const exponent = Math.floor(Math.log(value) / Math.log(k))
  const n = parseFloat((value / Math.pow(k, exponent)).toFixed(precision))

  return [n, byteSizes[exponent]]
}

/**
 * prettyBytes
 * ? Преобразует число в байтах в удобочитаемую строку
 *
 * @param {number} num величина байта
 * @param {number} precision точность
 * @param {boolean} addSpace пробел между единицами измерениями
 * @returns {string} высчитанная величина
 */
export const prettyBytes = (num: number, precision = 0, addSpace = true) => {
  if (num === null || num === undefined) {
    return ''
  }

  const space = addSpace ? ' ' : ''
  const negative = num < 0 ? '-' : ''

  const [value, type] = convertBytes(num, precision)

  const unitComplete = `${negative}${value}${space}${App.$i18n.t(
    `unit.${type}`
  )}`

  /**
   * Запрос от тех. писателя - Ксении Хорошиловой
   * На русском дробная часть должна отделяться запятой, а не точкой
   * Итого, для ru-RU используем запятую "5,15 TB" для en-US используем точку "5.15 TB"
   */
  return App.$i18n.locale === LOCALE.RU
    ? unitComplete.replace(/\./, ',')
    : unitComplete.replace(/,/, '.')
}

/**
 * upperFirstLetter
 * ? Сформировать первую букву большой
 *
 * @param {string} string строка
 * @returns {string} строка
 */
export const upperFirstLetter = (string: string) => {
  return string[0].toUpperCase() + string.slice(1)
}

/**
 * getQueryParam
 * ? Получить параметры запроса
 *
 * @param {string | null} paramKey свойство ключа запроса (необязательный)
 * @param {string} query строка запроса (необязательный)
 * @returns {SimpleKeys | string | number} строка запроса или значение запроса
 */
export const getQueryParam = (
  paramKey?: string | null,
  query?: string
): SimpleKeys | string | number => {
  const search = query || location.search

  if (!search) {
    return {}
  }

  const urlSearchParams = new URLSearchParams(search)

  if (typeof paramKey === 'string') {
    const URLSearchParam = new URLSearchParams(urlSearchParams).get(paramKey)

    return URLSearchParam === null ? {} : URLSearchParam
  }

  return Object.fromEntries(urlSearchParams.entries())
}

/**
 * initQueryParams
 * ? Получить объект содержащий в себе "page" (текущая страница), "query" (декодированные query параметры учитывающие "возвращаемый запрос") и "return_to" признак учитывающий содержание возвращаемого запроса или не учитывающий
 *
 * @param {string} returnedQueryKey возвращаемый ключ запроса (по умолчанию 'return_to')
 * @returns {DecodedOptionsQuery} параметры url (page, path, query, return_to)
 */
export const initQueryParams = (
  returnedQueryKey = 'return_to'
): DecodedOptionsQuery => {
  const queryReturnTo = getQueryParam(returnedQueryKey)
  const decodedOptionsQuery: DecodedOptionsQuery = {
    page: '',
    path: '',
    query: {},
    return_to: false,
  }

  if (typeof queryReturnTo === 'string') {
    const path = decodeURIComponent(queryReturnTo)

    if (!path) {
      return decodedOptionsQuery
    }

    const [pathname, query] = path.split('?')
    const [page] = pathname.split('/').filter((item) => item)
    const queryParam = getQueryParam(null, query)

    decodedOptionsQuery.page = page
    decodedOptionsQuery.path = path
    decodedOptionsQuery.return_to = true
    decodedOptionsQuery.query = typeof queryParam === 'object' ? queryParam : {}
  } else {
    const queryParam = getQueryParam()
    const [, path] = location.href.split('/ui')

    if (!path) {
      return decodedOptionsQuery
    }

    const [pathname] = path.split('?')
    const [page] = pathname.split('/').filter((item) => item)

    decodedOptionsQuery.page = page
    decodedOptionsQuery.path = path
    decodedOptionsQuery.return_to = false
    decodedOptionsQuery.query = typeof queryParam === 'object' ? queryParam : {}
  }

  if (
    typeof decodedOptionsQuery.query === 'object' &&
    decodedOptionsQuery.query[returnedQueryKey]
  ) {
    delete decodedOptionsQuery.query[returnedQueryKey]
  }

  return decodedOptionsQuery
}

/**
 * getMetaGroups
 * ? Получить актуальные мета данные групп
 *
 * @param {string} type тип таблицы
 * @param {TMetaGroups} metaPayload группа переданных метаданных
 * @param {TMetaGroups} metaState группа сохраненных метаданных
 * @returns {TActualMetaGroups} актуальные метаданные групп
 */
export const getMetaGroups = (
  type: string,
  metaPayload: TMetaGroups = {},
  metaState: TMetaGroups
): TActualMetaGroups => {
  const metaStorage = getItemStorage(TABLE_TYPE[type]) ?? {}
  const defaultGroups = Object.fromEntries(
    Object.entries(QUERY_KEYS[type]).map(([key]) => [key, {}])
  )

  type TGroups = keyof typeof defaultGroups
  type TGroupPayload = keyof TMetaGroups

  return Object.keys(defaultGroups).reduce((acc, group) => {
    acc[group as TGroups] =
      group in metaPayload
        ? metaPayload[group as TGroupPayload]
        : !isEmptyObject(metaStorage) && !isEmptyObject(metaStorage[group])
        ? metaStorage[group]
        : metaState[group as TGroupPayload] ?? {}

    return acc
  }, defaultGroups)
}
