import type {
  IDaDataCompanyPayload,
  IDaDataCompanyRequest,
} from '@/api/services/dadata/types'

import axios from '@/api/axios'

export default {
  /** ==== Организация ==== */

  /**
   * getCompany
   * ? Вернуть все доступные сведения о компании
   *
   * @param {IDaDataCompanyPayload} data данные по компании
   * @returns {Promise<IDaDataCompanyRequest>} вся информация по компании
   */
  getCompany(data: IDaDataCompanyPayload): Promise<IDaDataCompanyRequest> {
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${process.env.VUE_APP_DADATA_API_KEY}`,
        'Content-Type': 'application/json',
      },
    }

    return axios.post(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
      data,
      config
    )
  },
}
