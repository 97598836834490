import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  UserState,
  IReportUserAccountState,
  IUserOrdersLocalMeta,
  IUserBackupsLocalMeta,
} from '@/store/modules/admin/user/types'
import type {
  MetaResponse,
  OrderResponse,
  ProductResponse,
  UserBackupArchives,
  ProductQuotaResponse,
  AccountActivationType,
} from '@/api/types'

import {
  hasDate,
  hasUserName,
  getCountOrders,
  filterLicenses,
  hasAutoRenewal,
  hasTrialLicense,
  hasCloudLicenses,
  getFormattedSize,
  getFormattedDate,
  getFormattedPrice,
  getFormattedUserName,
  getFormattedUserPhone,
  getFormattedOrderType,
  getCountActiveLicenses,
  getFormattedLicenseType,
  getFormattedSimpleValue,
  getCountExpiredLicenses,
  getStatusExpirationDate,
  getFormattedMixCloudLicenses,
  getFormattedLicenseProductName,
  getFormattedCloudLicenseLimit,
  hasExpiredTrialFromLicenseList,
  getFormattedProductLicensedMachines,
} from '@/helpers/formatted-functions'

type UserTree = GetterTree<UserState, RootState>

import { App } from '@/main'
import { LocaleMessage } from 'vue-i18n'

export const getters: UserTree = {
  /**
   * hasUser
   * ? Признак существования пользователя
   *
   * @param {UserState} state data
   * @returns {boolean} признак существования пользователя
   */
  hasUser: (state): boolean => {
    return state.user !== null
  },

  /**
   * user
   * ? Пользовательский аккаунт
   *
   * @param {UserState} state data
   * @returns {IReportUserAccountState | null} пользовательский аккаунт
   */
  user: (state): IReportUserAccountState | null => {
    const { user } = state

    if (!user) {
      return null
    }

    const { phone, created_date } = user

    return {
      ...user,
      hasUserName: hasUserName(user),
      userName: getFormattedUserName(user),
      countOrders: getCountOrders(state.orders),
      formatUserPhone: getFormattedUserPhone(phone),
      hasCloudLicenses: hasCloudLicenses(state.quotas),
      createdFormatDate: getFormattedDate(created_date),
      countActiveLicenses: getCountActiveLicenses(state.licenses),
      countExpiredLicenses: getCountExpiredLicenses(state.licenses),
      formatMixCloudLicenses: getFormattedMixCloudLicenses(state.quotas),
      hasExpiredTrialLicense: hasExpiredTrialFromLicenseList(state.licenses),
    }
  },

  /**
   * userLicenses
   * ? Отфильтрованный список лицензий пользователя
   *
   * @param {UserState} state data
   * @returns {Array<ProductResponse>} отфильтрованный список лицензий пользователя
   */
  userLicenses: (state): ((typeLicense: string) => Array<ProductResponse>) => {
    return (typeLicense: string): Array<ProductResponse> => {
      return filterLicenses(state.licenses, typeLicense).map((license) => {
        const { id, sku, order_id, expiration_date } = license
        const quotas: ProductQuotaResponse[] = sku.quotas

        return {
          ...license,
          autoRenewal: hasAutoRenewal(license),
          hasTrialLicense: hasTrialLicense(sku),
          licenseType: getFormattedLicenseType(sku),
          formatLicenseId: getFormattedSimpleValue(id),
          formatSkuId: getFormattedSimpleValue(sku.id),
          hasMaxExpirationDate: hasDate(expiration_date),
          formatOrderId: getFormattedSimpleValue(order_id),
          productName: getFormattedLicenseProductName(license),
          formatExpirationDate: getFormattedDate(expiration_date),
          formatCloudLicense: getFormattedCloudLicenseLimit(quotas),
          countComputers: getFormattedProductLicensedMachines(license),
          statusExpirationDate: getStatusExpirationDate(expiration_date),
        }
      })
    }
  },

  /**
   * allLicenses
   * ? Список всех лицензий пользователя
   *
   * @param {UserState} state data
   * @param {object} getters computed
   * @returns {Array<ProductResponse>} список всех лицензий пользователя
   */
  allLicenses: (state, getters): Array<ProductResponse> => {
    return getters.userLicenses()
  },

  /**
   * activeLicenses
   * ? Список активных лицензий пользователя
   *
   * @param {UserState} state data
   * @param {object} getters computed
   * @returns {Array<ProductResponse>} список активных лицензий пользователя
   */
  activeLicenses: (state, getters): Array<ProductResponse> => {
    return getters.userLicenses('active')
  },

  /**
   * expiredLicenses
   * ? Список истекших лицензий пользователя
   *
   * @param {UserState} state data
   * @param {object} getters computed
   * @returns {Array<ProductResponse>} список истекших лицензий пользователя
   */
  expiredLicenses: (state, getters): Array<ProductResponse> => {
    return getters.userLicenses('expired')
  },

  /**
   * userOrders
   * ? Ордера пользователя
   *
   * @param {UserState} state data
   * @returns {Array<OrderResponse>} ордера пользователя
   */
  userOrders: (state): Array<OrderResponse> => {
    return state.orders.map((orders) => {
      const {
        id,
        type,
        amount,
        license_id,
        partner_id,
        campaign_id,
        creation_date,
        completion_date,
        delivered_license_id,
      } = orders
      const licenseID = license_id || delivered_license_id

      return {
        ...orders,
        licenseID,
        formatPrice: getFormattedPrice(amount, 2),
        formatOrderId: getFormattedSimpleValue(id),
        formatOrderType: getFormattedOrderType(type),
        formatLicenseId: getFormattedSimpleValue(licenseID),
        formatCreationDate: getFormattedDate(creation_date),
        formatPartnerId: getFormattedSimpleValue(partner_id),
        formatCampaignId: getFormattedSimpleValue(campaign_id),
        formatCompletionDate: getFormattedDate(completion_date),

        /**
         * На текущий момент договорились,
         * что типы "Покупки" и "Оплаты" будут статикой.
         */
        paymentType: App.$i18n.t('field.label.online'),
        purchaseType: App.$i18n.t('field.label.cashless'),
      }
    })
  },

  /**
   * userBackups
   * ? Резервные копии пользователя
   *
   * @param {UserState} state data
   * @returns {Array<UserBackupArchives>} резервные копии пользователя
   */
  userBackups: (state): Array<UserBackupArchives> => {
    return state.backups.map((backup) => {
      const { name, size, created_at } = backup

      return {
        ...backup,
        formatSize: getFormattedSize(size),
        formatName: getFormattedSimpleValue(name),
        formatCreatedDate: getFormattedDate(created_at),
      }
    })
  },

  /**
   * accountActivationType
   * ? Тип активации для аккаунта
   *
   * @param {UserState} state data
   * @returns {AccountActivationType | null} тип активации для аккаунта
   */
  accountActivationType: (state: UserState): AccountActivationType | null => {
    return state.activationType
  },

  /**
   * accountActivityTypeText
   * ? Текст типа активации аккаунта
   *
   * @param {UserState} state data
   * @returns {string} Текст типа активации аккаунта
   */
  accountActivityTypeText: (state: UserState): LocaleMessage | string => {
    const activationType: AccountActivationType | null = state.activationType

    if (!activationType) {
      return ''
    }
    const { activation_type } = activationType

    switch (activation_type) {
      case 'complete_account':
        return App.$i18n.t('account.status.no-activated')

      case 'confirmation_email':
        return App.$i18n.t('account.status.no-confirmed')

      default:
        return ''
    }
  },

  /**
   * accountLinkGenerationText
   * ? Текст генерации ссылки аккаунта
   *
   * @param {UserState} state data
   * @returns {string} текст генерации ссылки аккаунта
   */
  accountLinkGenerationText: (state: UserState): LocaleMessage | string => {
    const activationType: AccountActivationType | null = state.activationType

    if (!activationType) {
      return ''
    }

    const { activation_type } = activationType

    switch (activation_type) {
      case 'complete_account':
        return App.$i18n.t('component.table.dropdown.text.complete-account')

      case 'confirmation_email':
        return App.$i18n.t('component.table.dropdown.text.confirmation-email')

      default:
        return ''
    }
  },

  /**
   * ordersMeta
   * ? Мета данные пагинации ордеров
   *
   * @param {UserState} state data
   * @returns {MetaResponse} мета данные пагинации ордеров
   */
  ordersMeta: (state): MetaResponse => {
    return state.ordersMeta
  },

  /**
   * backupsMeta
   * ? Мета данные пагинации бэкапов
   *
   * @param {UserState} state data
   * @returns {MetaResponse} мета данные пагинации бэкапов
   */
  backupsMeta: (state): MetaResponse => {
    return state.backupsMeta
  },

  /**
   * currentOrdersMeta
   * ? Текущие мета данные пагинации ордеров
   *
   * @param {UserState} state data
   * @returns {IUserOrdersLocalMeta} текущие мета данные пагинации ордеров
   */
  currentOrdersMeta: (state): IUserOrdersLocalMeta => {
    return state.currentOrdersMeta
  },

  /**
   * currentBackupsMeta
   * ? Текущие мета данные бэкапов
   *
   * @param {UserState} state data
   * @returns {IUserBackupsLocalMeta} текущие мета данные бэкапов
   */
  currentBackupsMeta: (state): IUserBackupsLocalMeta => {
    return state.currentBackupsMeta
  },

  /**
   * activeTab
   * ? Активный таб пользователя
   *
   * @param {UserState} state data
   * @returns {string} активный таб пользователя
   */
  activeTab: (state): string => {
    return state.activeTab
  },

  /**
   * isLoadingUser
   * ? Процесс загрузки пользователя
   *
   * @param {UserState} state data
   * @returns {boolean} процесс загрузки пользователя
   */
  isLoadingUser: (state): boolean => {
    return state.isLoadingUser
  },

  /**
   * isLoadingLicenses
   * ? Процесс загрузки лицензий
   *
   * @param {UserState} state data
   * @returns {boolean} процесс загрузки лицензий
   */
  isLoadingLicenses: (state): boolean => {
    return state.isLoadingLicenses
  },

  /**
   * isLoadingAllDataUser
   * ? Процесс загрузки всех пользовательских данных
   *
   * @param {UserState} state data
   * @returns {boolean} процесс загрузки всех пользовательских данных
   */
  isLoadingAllDataUser: (state): boolean => {
    return state.isLoadingAllDataUser
  },

  /**
   * isLoadingRevoke
   * ? Процесс удаления лицензии
   *
   * @param {UserState} state data
   * @returns {boolean} процесс удаления лицензии
   */
  isLoadingRevoke: (state): boolean => {
    return state.isLoadingRevoke
  },

  /**
   * isLoadingOrders
   * ? Процесс загрузки ордеров
   *
   * @param {UserState} state data
   * @returns {boolean} процесс загрузки оредров
   */
  isLoadingOrders: (state): boolean => {
    return state.isLoadingOrders
  },

  /**
   * isLoadingBackups
   * ? Процесс загрузки резервных копий пользователя
   *
   * @param {UserState} state data
   * @returns {boolean} процесс загрузки резервных копий пользователя
   */
  isLoadingBackups: (state): boolean => {
    return state.isLoadingBackups
  },

  /**
   * isLoadingActivationType
   * ? Процесс загрузки типов активаций аккаунта
   *
   * @param {UserState} state data
   * @returns {boolean} процесс загрузки типов активаций аккаунта
   */
  isLoadingActivationType: (state): boolean => {
    return state.isLoadingActivationType
  },

  /**
   * isLoadingDeleteMultipleBackupItems
   * ? Процесс удаления одной или множества резервных копий пользователя
   *
   * @param {UserState} state data
   * @returns {boolean} процесс удаления одной или множества резервных копий пользователя
   */
  isLoadingDeleteMultipleBackupItems: (state): boolean => {
    return state.isLoadingDeleteMultipleBackupItems
  },

  /**
   * isGroupLoadingActivationType
   * ? Процесс множественной загрузки активаций аккаунта и верификации почты
   *
   * @param {UserState} state data
   * @returns {boolean} процесс множественной загрузки активаций аккаунта и верификации почты
   */
  isGroupLoadingActivationType: (
    state: UserState
  ): ((name: string) => boolean) => {
    return (name) => state.groupLoadingActivationType[name]
  },
}
