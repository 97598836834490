<template>
  <el-dialog
    position="center"
    class="vpc-modal-common-error-dialog"
    :visible="visible"
    :show-close="false"
    :close-on-click-modal="false"
    :variant="isMobile ? 'fullscreen' : 'clean-basic'"
    @close="closeModal"
  >
    <div class="vpc-modal-common-error-content">
      <div class="vpc-modal-common-error-title-box">
        <vpc-icon
          name="circle-error"
          width="16"
          height="16"
          :color="colorRedPrimary"
        />

        <h2 class="vpc-modal-common-error-title">
          {{ $t('modal.common-error.title') }}
        </h2>
      </div>

      <p class="vpc-modal-common-error-info">
        {{ errorMessage }}
        <br />
        <span v-if="!hasCustomError">{{
          $t('modal.common-error.text.try-after')
        }}</span>
      </p>

      <div class="vpc-modal-common-error-button-box">
        <el-button
          variant="outline-primary"
          @click="closeModal"
        >
          {{ $t('modal.common-error.button') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import VpcIcon from '@/components/common/vpc-icon.vue'
import style from '@/assets/styles/_export-var.module.scss'

export default {
  name: 'VpcModalCommonError',
  components: {
    VpcIcon,
  },
  model: {
    prop: 'visible',
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    colorRedPrimary: style.colorRedPrimary,
  }),
  computed: {
    ...mapGetters(['isMobile', 'userCommonErrorMessage']),

    errorMessage() {
      return (
        this.userCommonErrorMessage ||
        this.$t('modal.common-error.text.work-with-problem')
      )
    },

    hasCustomError() {
      return typeof this.userCommonErrorMessage === 'string'
    },
  },
  methods: {
    closeModal() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss">
.vpc-modal-common-error-dialog {
  & .el-dialog--width-small.el-dialog {
    width: 48rem;
    min-width: 48rem;
  }
}
.vpc-modal-common-error-content {
  padding: 2.4rem;
}
.vpc-modal-common-error-title-box {
  display: flex;
  align-items: center;
  margin-bottom: 2.4rem;
}
.vpc-modal-common-error-title {
  margin-left: 0.6rem;
}
.vpc-modal-common-error-info {
  font-weight: $lk-font-weight-s;
  line-height: 1.68rem;
  margin-bottom: 2.4rem;
}
.vpc-modal-common-error-button-box {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
