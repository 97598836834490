import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'

type RootTree = ActionTree<RootState, RootState>
type RootContext = ActionContext<RootState, RootState>

export const actions: RootTree = {
  /**
   * beforeInitApp
   * ? Возможность выполнять n запросов до инициализации приложения
   *
   * @param {RootContext} param0 context
   * @param {Function} param0.dispatch action
   * @returns {Promise<void>}
   */
  beforeInitApp: async ({ dispatch }: RootContext): Promise<void> => {
    await dispatch('listenEvents')
    await dispatch('account/setAccount')
  },

  /**
   * listenEvents
   * ? Подписаться на события, для установки размера документа
   *
   * @param {RootContext} param0 context
   * @returns {void}
   */
  listenEvents: ({ commit }: RootContext): void => {
    const setDisplaySizes = () => commit('SET_DISPLAY_SIZES')

    window.addEventListener('resize', setDisplaySizes)
    window.addEventListener('DOMContentLoaded', setDisplaySizes)
  },
}
