import type { GetterTree } from 'vuex'
import type {
  SkuFeature,
  SkuState,
  ISkuRuleAction,
} from '@/store/modules/admin/sku/types'
import type { RootState } from '@/store/types'
import {
  getCloudLicense,
  getFormattedCloudLicenseLimit,
  getFormattedDate,
  getFormattedLicenseProductName,
  getFormattedLicenseType,
  getFormattedPrice,
  getStatus,
  getSubscriptionPeriod,
} from '@/helpers/formatted-functions'
import type { LocaleMessage } from 'vue-i18n'
import type {
  SkuCloudQuotaType,
  SkuDurationType,
  SkuForm,
} from '@/components/forms/admin/types'
import { ELicenseType } from '@/config/enums'
import { convertBytes } from '@/helpers'
import { FEATURES_LIMIT } from '@/store/modules/admin/sku/index'
import { IUpgradeSkuResponse } from '@/api/types'

type SkuTree = GetterTree<SkuState, RootState>

export const getters: SkuTree = {
  /**
   * currentSku
   * ? Текущий SKU
   *
   * @param {SkuState} state data
   * @returns {Record<string, string | LocaleMessage | number | IUpgradeSkuResponse[]>} Текущий SKU
   */
  currentSku(
    state: SkuState
  ): Record<
    string,
    string | LocaleMessage | number | IUpgradeSkuResponse[]
  > | null {
    if (state.currentSku === null || state.isLoadingCurrentSku) {
      return null
    }
    const {
      ttl,
      price,
      activations_limit,
      state: status,
      quotas,
      product_id,
      id,
      description,
      name,
      store_product_id,
    } = state.currentSku

    return {
      id,
      description,
      name,
      status,
      store_product_id,
      activations_limit,
      cloudQuotaSoftLimit: getCloudLicense(quotas)?.soft_limit ?? 0,
      formatStatus: getStatus(status),
      formatPrice: getFormattedPrice(price, 0, false),
      formatPeriod: getSubscriptionPeriod(ttl),
      formatLicenseType: getFormattedLicenseType(ttl),
      formatCloudStorage: getFormattedCloudLicenseLimit(quotas),
      formatProductName: getFormattedLicenseProductName(product_id),
      // Временное решение пока этих данных нет в API
      formatArchivedDate: getFormattedDate(null),
      formatModificationDate: getFormattedDate(null),
      formatCreatedDate: getFormattedDate(null),
    }
  },

  /**
   * isLoadingCreateSku
   * ? Процесс добавления SKU
   *
   * @param {SkuState} state data
   * @returns {boolean} процесс добавления SKU
   */
  isLoadingAddSku: (state: SkuState): boolean => {
    return state.isLoadingAddSku
  },

  /**
   * isLoadingCurrentSku
   * ? Процесс загрузки SKU
   *
   * @param {SkuState} state data
   * @returns {boolean} процесс загрузки SKU
   */
  isLoadingCurrentSku: (state: SkuState): boolean => {
    return state.isLoadingCurrentSku
  },

  /**
   * isLoadingDeleteSku
   * ? Процесс удаления SKU
   *
   * @param {SkuState} state data
   * @returns {boolean} процесс удаления SKU
   */
  isLoadingDeleteSku: (state: SkuState): boolean => {
    return state.isLoadingDeleteSku
  },

  /**
   * isLoadingEditingPriceSku
   * ? Процесс изменения цены SKU
   *
   * @param {SkuState} state data
   * @returns {boolean} процесс изменения цены SKU
   */
  isLoadingEditingPriceSku: (state: SkuState): boolean => {
    return state.isLoadingEditingPriceSku
  },

  /**
   * isLoadingEditingUpgradesSku
   * ? Процесс изменения апгрейдов SKU
   *
   * @param {SkuState} state data
   * @returns {boolean} процесс изменения апгрейдов SKU
   */
  isLoadingEditingUpgradesSku: (state: SkuState): boolean => {
    return state.isLoadingEditingUpgradesSku
  },

  /**
   * isLoadingEditSku
   * ? Процесс изменения SKU
   *
   * @param {SkuState} state data
   * @returns {boolean} процесс изменения SKU
   */
  isLoadingEditSku: (state: SkuState): boolean => {
    return state.isLoadingEditSku
  },

  /**
   * isLoadingPublish
   * ? Процесс публикации SKU
   *
   * @param {SkuState} state data
   * @returns {boolean} процесс публикации SKU
   */
  isLoadingPublishSku: (state: SkuState): boolean => {
    return state.isLoadingPublishSku
  },

  /**
   * isLoadingArchivingSku
   * ? Процесс архивации SKU
   *
   * @param {SkuState} state data
   * @returns {boolean} процесс архивации SKU
   */
  isLoadingArchivingSku: (state: SkuState): boolean => {
    return state.isLoadingArchivingSku
  },

  /**
   * isLoadingFeatures
   * ? Процесс загрузки списка возможностей SKU
   *
   * @param {SkuState} state data
   * @returns {boolean} Процесс загрузки списка возможностей SKU
   */
  isLoadingFeatures: (state: SkuState): boolean => {
    return state.isLoadingFeatures
  },

  /**
   * featureName
   * ? Возвращает название возможности (feature) по её идентификатору
   *
   * @returns {string | LocaleMessage} название возможности
   */
  featureName: (): ((featureId: string) => string) => {
    return (featureId: string) => {
      const names: Record<string, string> = {
        FEATURE_ARCHIVING: 'Archiving',
        FEATURE_BOOTABLE_RECOVERY_MEDIA: 'Bootable Recovery Media',
        FEATURE_CYBER_SECURE_ZONE: 'Cyber Secure Zone',
        FEATURE_DISK_CLONING: 'Disk Cloning',
        FEATURE_FILE_BACKUP: 'File Backup',
        FEATURE_IMAGE_BACKUP: 'Image Backup',
        FEATURE_ONLINE_BACKUP: 'Online Backup',
        FEATURE_SCHEDULING: 'Scheduling',
        FEATURE_STARTUP_RECOVERY_MANAGER: 'Startup Recovery Manager',
        FEATURE_UR: 'UR',
      }

      return names[featureId] || ''
    }
  },

  /**
   * currentSkuForm
   * ? Геттер для формы создания/редактирования SKU
   *
   * @param {SkuState} state data
   * @returns {string | LocaleMessage} Форма создания/редактирования SKU
   */
  currentSkuForm: (state: SkuState): SkuForm | null => {
    const currentSku = state.currentSku

    if (!currentSku) {
      return null
    }

    const licenseType: ELicenseType =
      currentSku.ttl === '1y' ? ELicenseType.SUBSCRIPTION : ELicenseType.TRIAL

    const [cloudQuotaSize, cloudQuotaType] = convertBytes(
      getCloudLicense(currentSku.quotas)?.soft_limit_raw ?? 0
    )

    // Необходимо перевести байты в гигабайты или терабайты
    const cloudQuota: SkuCloudQuotaType | null =
      cloudQuotaSize > 0 && (cloudQuotaType === 'tb' || cloudQuotaType === 'gb')
        ? {
            value: cloudQuotaSize,
            type: cloudQuotaType,
          }
        : null

    // Маппинг продолжительности подписки
    const duration: SkuDurationType = {
      value: Number(currentSku.ttl.replace(/[a-z]/g, '')),
      type: currentSku.ttl.replace(/[\d ]/g, '') as 'y' | 'd',
    }

    return {
      id: currentSku.id,
      includedVat: currentSku.vat_included,
      description: currentSku.description,
      price: Number(currentSku.price || 0),
      pcQuota: currentSku.activations_limit,
      productId: currentSku.product_id,
      name: currentSku.name,
      licenseType,
      cloudQuota,
      duration,
      features: currentSku.features,
    }
  },

  /**
   * skuRulesAction
   * ? Правила действий для SKU
   *
   * @param {SkuState} state data
   * @returns {ISkuRuleAction} правила действий для SKU
   */
  skuRulesAction: ({ currentSku, features }: SkuState): ISkuRuleAction => {
    if (currentSku === null) {
      return {
        edit: false,
        editPrice: false,
        delete: false,
        publish: false,
        archive: false,
        viewFeatures: false,
        viewAllFeatures: false,
        viewAndSelectAllFeatures: false,
        viewAndSelectFeatures: false,
        selectFeatures: false,
      }
    }

    const featuresCount = features?.length ?? 0 // Все возможности
    const selectedFeaturesCount = currentSku.features?.length ?? 0 // Выбранные возможности

    const { state } = currentSku
    const isDraft = state === 'draft' // Черновик
    const isPublished = state === 'published' // Опубликовано
    const isArchived = state === 'archived' // В архиве

    return {
      edit: isDraft, // Редактирование SKU
      editPrice: isDraft || isPublished, // Изменение цены SKU
      delete: isDraft, // Удаление SKU
      publish: isDraft, // Публикация SKU
      archive: isPublished, // Архивация SKU

      // Черновик, не выбраны
      selectFeatures: isDraft && selectedFeaturesCount === 0,
      // Опубликован или в архиве, частично выбраны, показаны все
      viewFeatures:
        (isArchived || isPublished) &&
        selectedFeaturesCount > 0 &&
        featuresCount <= FEATURES_LIMIT,
      // Опубликован или в архиве, частично выбраны, показаны НЕ все
      viewAllFeatures:
        (isArchived || isPublished) &&
        selectedFeaturesCount > 0 &&
        featuresCount > FEATURES_LIMIT,
      // Черновик, частично выбраны, показаны все
      viewAndSelectFeatures:
        isDraft && selectedFeaturesCount > 0 && featuresCount <= FEATURES_LIMIT,
      // Черновик, частично выбраны, показаны НЕ все
      viewAndSelectAllFeatures:
        isDraft && selectedFeaturesCount > 0 && featuresCount > FEATURES_LIMIT,
    }
  },

  /**
   * skuFeatures
   * ? Возвращает список возможностей для SKU
   *
   * @param {SkuState} state state
   * @returns {SkuFeature[]} список возможностей для SKU
   */
  skuFeatures: ({ features }: SkuState): SkuFeature[] => features,

  /**
   * skuUpgrades
   * ? Возвращает список апгрейдов для SKU
   *
   * @param {SkuState} state state
   * @returns {IUpgradeSkuResponse[]} список апгрейдов для SKU
   */
  skuUpgrades: ({ currentSku }: SkuState): IUpgradeSkuResponse[] => {
    return currentSku?.upgrades ?? []
  },
}
