import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import routes from '@/router/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const homePage = store.getters['nav/homePage']
  const currentRole = store.getters['account/userRole']
  const isNewWebrestore = store.getters['nav/isNewWebrestore']
  const isUserLoggedIn = store.getters['account/isUserLoggedIn']
  const isAccountDeleted = store.getters['account/isAccountDeleted']
  const guardAuthRequired = to.matched.some(
    (item) => item.meta.guardAuthRequired
  )

  // * Исключения
  if (isUserLoggedIn) {
    /**
     * ? Возвращаем на главную, в случае:
     * если аутентифицированный пользователь хочет зайти на страницу login
     */
    if (to.name === 'login') {
      next({ name: homePage.name })

      return
    }

    if (guardAuthRequired) {
      const isWebRestoreSectionEntry =
        to.name && ['backups', 'backup'].includes(to.name)
      const isPermitApproved = to.matched.some(({ meta }) =>
        meta.roles.includes(currentRole)
      )

      /**
       * ? Распределение маршрутов между ролями:
       * для аутентифицированного с необходимой ролью
       *
       * Отправляем на стартовую страницу в случае
       * - если нет прав для текущего раздела
       * - если была попытка входа в раздел резервных копий а признак (переключатель) на новый Web Restore был отключен
       */
      if (!isPermitApproved || (isWebRestoreSectionEntry && !isNewWebrestore)) {
        next({ name: homePage.name })
        return
      }

      next()
      return
    }
  }

  if (guardAuthRequired) {
    /**
     * ? Возвращаем на login, в случае:
     * если не аутентифицированный пользователь хочет зайти на страницы требующие обязательную аутентификацию
     */

    next({
      name: 'login',
      query: {
        return_to: encodeURIComponent(to.fullPath),
      },
    })

    return
  }

  // * Исключения для корня
  if (to.name === 'root') {
    next({ name: 'login' })

    return
  }

  // * Исключение для страницы удаленного аккаунта
  if (to.name === 'deleted' && !isAccountDeleted) {
    next({ name: 'login' })

    return
  }

  // * Далее открытые переходы
  next()
})

export default router
