import type {
  OrdersState,
  OrdersModule,
} from '@/store/modules/admin/orders/types'

import { getters } from '@/store/modules/admin/orders/getters'
import { actions } from '@/store/modules/admin/orders/actions'
import { mutations } from '@/store/modules/admin/orders/mutations'

const getOrderCampaignsMeta = () => {
  return {
    meta: {
      paging: {
        page: 1,
        limit: 20,
      },
      sort: {
        direction: 'desc',
        order_by: 'created_date',
      },
      filters: {
        only_public: true,
      },
    },
  }
}

const getReportOrdersMeta = () => {
  return {
    total: 100,
    maxPages: 10,
    meta: {
      paging: {
        page: 1,
        limit: 20,
      },
      sort: {
        direction: 'desc',
        order_by: 'creation_date',
      },
    },
  }
}

const state: OrdersState = {
  campaigns: [],
  reportOrders: [],
  isLoadingCampaigns: false,
  isLoadingReportOrders: false,
  isLoadingExportOrders: false,
  defaultOrderCampaignsMeta: getOrderCampaignsMeta(),
  currentOrderCampaignsMeta: getOrderCampaignsMeta(),
  defaultReportOrdersMeta: getReportOrdersMeta(),
  currentReportOrdersMeta: getReportOrdersMeta(),
}

export const orders: OrdersModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
