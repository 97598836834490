import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  IArchivesState,
  IArchiveVersions,
  ArchivesLocalMeta,
} from '@/store/modules/client/archives/types'
import type { IWrBackupItemResponse } from '@/api/types'

type ArchivesTree = GetterTree<IArchivesState, RootState>

import { getWrIcons } from '@/helpers/wr-icons'
import {
  getFormattedSize,
  getFormattedDate,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

export const getters: ArchivesTree = {
  /**
   * hasArchives
   * ? Признак наличия архивов
   *
   * @param {IArchivesState} state data
   * @returns {boolean} признак наличия архивов
   */
  hasArchives: (state: IArchivesState): boolean => {
    return state.archives.length > 0
  },

  /**
   * archives
   * ? Список архивов
   *
   * @param {IArchivesState} state data
   * @returns {IWrBackupItemResponse[]} список архивов
   */
  archives: (state: IArchivesState): IWrBackupItemResponse[] => {
    return state.archives.map((archive: IWrBackupItemResponse) => {
      const { name, size, subtype, created_at, display_name } = archive

      return {
        ...archive,
        icon: getWrIcons(subtype, 'list'),
        formatSize: getFormattedSize(size),
        formatName: getFormattedSimpleValue(name),
        formatCreatedDate: getFormattedDate(created_at),
        formatDisplayName: getFormattedSimpleValue(display_name),
        formatCreatedDateTime: getFormattedDate(created_at, true),
      }
    })
  },

  /**
   * archiveVersionMap
   * ? Карта списка архивов с содержимым всех имеющихся версий
   *
   * @param {IArchivesState} state data
   * @returns {IArchiveVersions} Карта списка архивов с содержимым всех имеющихся версий
   */
  archiveVersionMap: (state: IArchivesState): IArchiveVersions => {
    return state.archiveVersionMap
  },

  /**
   * currentArchiveVersion
   * ? Текущая версия архива
   *
   * @param {IArchivesState} state data
   * @returns {ArchivesLocalMeta} текущая версия архива
   */
  currentArchiveVersion:
    (state: IArchivesState): ((name: string) => number) =>
    (name: string): number => {
      const versions = (state.archiveVersionMap[name] || []).map(({ name }) =>
        Number(name)
      )

      return Math.max(...versions)
    },

  /**
   * currentArchivesMeta
   * ? Текущие мета списка архивов
   *
   * @param {IArchivesState} state data
   * @returns {ArchivesLocalMeta} текущие мета списка архивов
   */
  currentArchivesMeta: (state: IArchivesState): ArchivesLocalMeta => {
    return state.currentArchivesMeta
  },

  /**
   * isLoadingArchives
   * ? Процесс загрузки списка архивов
   *
   * @param {IArchivesState} state data
   * @returns {boolean} процесс загрузки списка архивов
   */
  isLoadingArchives: (state: IArchivesState): boolean => {
    return state.isLoadingArchives
  },

  /**
   * isLoadingArchiveDelete
   * ? Процесс удаления архива
   *
   * @param {IArchivesState} state data
   * @returns {boolean} процесс удаления архива
   */
  isLoadingArchiveDelete: (state: IArchivesState): boolean => {
    return state.isLoadingArchiveDelete
  },

  /**
   * isLoadingArchiveVersions
   * ? Процесс загрузки версий архива
   *
   * @param {IArchivesState} state data
   * @returns {boolean} процесс загрузки версий архива
   */
  isLoadingArchiveVersions: (state: IArchivesState): boolean => {
    return state.isLoadingArchiveVersions
  },
}
