import type { SkuState, SkuModule } from '@/store/modules/admin/sku/types'
import type { SkuForm } from '@/components/forms/admin/types'
import type { SkuPayload } from '@/api/types'
import { getters } from '@/store/modules/admin/sku/getters'
import { actions } from '@/store/modules/admin/sku/actions'
import { mutations } from '@/store/modules/admin/sku/mutations'
import { convertToGigaBytes } from '@/helpers'
import { ELicenseType } from '@/config/enums'

export const FEATURES_LIMIT = 10

const state: SkuState = {
  currentSku: null,
  features: [],

  isLoadingAddSku: false,
  isLoadingEditSku: false,
  isLoadingFeatures: false,
  isLoadingDeleteSku: false,
  isLoadingCurrentSku: false,
  isLoadingPublishSku: false,
  isLoadingArchivingSku: false,
  isLoadingEditingPriceSku: false,
  isLoadingEditingUpgradesSku: false,
}

export const sku: SkuModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}

export const getSkuPayload = (
  form: SkuForm,
  publishToSoftLine = false
): SkuPayload => {
  const cloudLimit = convertToGigaBytes(
    form.cloudQuota?.value || 0,
    form.cloudQuota?.type || 'gb'
  ) // Необходимо отправлять размер квоты в гигабайтах

  const payload: SkuPayload = {
    price: form.price,
    activations_limit: form.pcQuota,
    id: form.id,
    product_id: form.productId,
    name: form.name,
    description: form.description,
    ttl: `${form.duration.value}${form.duration.type}`,
    store_id: publishToSoftLine ? 'softline' : null,
    vat_included:
      form.licenseType === ELicenseType.SUBSCRIPTION ? form.includedVat : false,
    quotas: [
      {
        id: 'cloud',
        hard_limit: cloudLimit,
        soft_limit: cloudLimit,
      },
    ],
  }

  if (Array.isArray(form.features)) {
    payload.features = form.features.map((item) => ({ id: item.id }))
  }

  return payload
}
