import type { UserState, UserModule } from '@/store/modules/admin/user/types'

import { getters } from '@/store/modules/admin/user/getters'
import { actions } from '@/store/modules/admin/user/actions'
import { mutations } from '@/store/modules/admin/user/mutations'

const getOrdersMeta = () => {
  return {
    total: 100,
    maxPages: 10,
    meta: {
      paging: {
        page: 1,
        limit: 10,
      },
      sort: {
        direction: 'desc',
        order_by: 'creation_date',
      },
    },
  }
}

const getBackupsMeta = () => {
  return {
    total: 100,
    maxPages: 10,
    meta: {
      paging: {
        page: 1,
        limit: 10,
      },
      sort: {
        direction: 'desc',
        order_by: 'name',
      },
    },
  }
}

const state: UserState = {
  activeTab: '',
  user: null,
  activationType: null,
  orders: [],
  quotas: [],
  backups: [],
  licenses: [],
  ordersMeta: {},
  backupsMeta: {},
  defaultOrdersMeta: getOrdersMeta(),
  currentOrdersMeta: getOrdersMeta(),
  defaultBackupsMeta: getBackupsMeta(),
  currentBackupsMeta: getBackupsMeta(),
  isLoadingUser: false,
  isLoadingRevoke: false,
  isLoadingQuotas: false,
  isLoadingOrders: false,
  isLoadingBackups: false,
  isLoadingLicenses: false,
  isLoadingAllDataUser: false,
  isLoadingActivationType: false,
  isLoadingDeleteMultipleBackupItems: false,
  groupLoadingActivationType: {},
}

export const user: UserModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
