import type {
  ICampaignsState,
  TCampaignsModule,
} from '@/store/modules/admin/campaigns/types'

import { getters } from '@/store/modules/admin/campaigns/getters'
import { actions } from '@/store/modules/admin/campaigns/actions'
import { mutations } from '@/store/modules/admin/campaigns/mutations'

const getReportCampaignsMeta = () => {
  return {
    total: 100,
    maxPages: 10,
    meta: {
      paging: {
        page: 1,
        limit: 20,
      },
      sort: {
        direction: 'desc',
        order_by: 'created_date',
      },
    },
  }
}

const state: ICampaignsState = {
  reportCampaigns: [],
  isLoadingAddCampaign: false,
  isLoadingReportCampaigns: false,
  isLoadingExportCampaigns: false,
  defaultReportCampaignsMeta: getReportCampaignsMeta(),
  currentReportCampaignsMeta: getReportCampaignsMeta(),
}

export const campaigns: TCampaignsModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
