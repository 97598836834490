import type { MutationTree } from 'vuex'
import type { MetaResponse, TExternalLicenses } from '@/api/types'
import type { IExternalProductsState } from '@/store/modules/business/external-products/types'

type TExternalProductsTree = MutationTree<IExternalProductsState>

import { setLoading } from '@/helpers/mutation-functions'

export const mutations: TExternalProductsTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_EXTERNAL_LICENSES
   * ? Установить внешние лицензии
   *
   * @param {IExternalProductsState} state data
   * @param {TExternalLicenses} licenses внешние лицензии
   * @returns {void}
   */
  SET_EXTERNAL_LICENSES: (
    state: IExternalProductsState,
    licenses: TExternalLicenses
  ): void => {
    state.externalLicenses = licenses
  },

  /**
   * SET_EXTERNAL_LICENSES_META
   * ? Установить мета данные внешних лицензий
   *
   * @param {IExternalProductsState} state data
   * @param {MetaResponse} meta мета данные внешних лицензий
   * @returns {void}
   */
  SET_EXTERNAL_LICENSES_META: (
    state: IExternalProductsState,
    meta: MetaResponse
  ): void => {
    state.externalLicensesMeta = meta
  },
}
